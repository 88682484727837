import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { Modal } from "components";
import { checkLength, BANK_TYPE_OPTIONS, getLabelByCode } from "core";
import { AuthorityHelper, AddressPopup, BankCodeSelectPopup } from "domain";

// const testUid = "tec1500cf3161dc4237a92b4075c164fe65";
// authority/tec4801c5ff6d214e368934fdd238cb5690
const TYPE = { ADDR: "address", BANK: "bankCode" };

function ContractForm(props) {
  //===========================================================================
  const initFormData = () => {
    return {
      employeeJuminNum: "",
      employeeEmail: "",
      employeeAddr1: "",
      employeeAddr2: "",
      employeeBankCode: "",
      employeeBankNum: "",
      employeeBankOwner: "",
    };
  };
  const clearValidData = () => {
    return {
      is: false,
      employeeJuminNum: false,
      employeeEmail: false,
      employeeAddr1: false,
      employeeAddr2: false,
      employeeBankCode: false,
      employeeBankNum: false,
      employeeBankOwner: false,
    };
  };
  //===========================================================================
  const { $app, style, onChangeView, viewOptions } = props;
  const [formData, setFormData] = useState(initFormData());
  const [validData, setValidData] = useState(clearValidData);
  const [modalIsOpen, setModalIsOpen] = useState({
    address: false,
    bankCode: false,
  });
  const accessToken = useRecoilValue(AuthorityHelper.tokenState);
  //===========================================================================
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeJuminNumInput = (e) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/[^0-9-]/g, "");
    const numbersOnly = cleanedValue.replace(/-/g, "");

    let formattedValue = "";
    if (numbersOnly.length <= 6) {
      formattedValue = numbersOnly;
    } else {
      formattedValue = `${numbersOnly.substring(0, 6)}-${numbersOnly.substring(
        6
      )}`;
    }
    setFormData((prevState) => ({ ...prevState, [name]: formattedValue }));
  };

  const handleChangedNext = () => {
    if (validData.is) {
      onChangeView({
        view: viewOptions.DETAIL,
        contractData: formData,
      });
    }
  };
  const handleChangeModal = (type, open) => {
    setModalIsOpen({ ...modalIsOpen, [type]: open });
  };
  const handleSelectAddress = (address) => {
    const { roadAddrPart1 } = address;
    setFormData({
      ...formData,
      employeeAddr1: roadAddrPart1,
    });
  };
  //===========================================================================
  useEffect(() => {
    const checkValid = () => {
      const juminRule =
        /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-8][0-9]{6}$/;
      const emailRule =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      const {
        employeeJuminNum,
        employeeEmail,
        employeeAddr1,
        employeeAddr2,
        employeeBankCode,
        employeeBankNum,
        employeeBankOwner,
      } = formData;
      let tmp = clearValidData();
      tmp.employeeJuminNum = juminRule.test(employeeJuminNum);
      if (employeeEmail === "없음") tmp.employeeEmail = true;
      else tmp.employeeEmail = emailRule.test(employeeEmail);
      tmp.employeeAddr1 = checkLength(employeeAddr1);
      tmp.employeeAddr2 = checkLength(employeeAddr2);
      tmp.employeeBankCode = !!Number(employeeBankCode);
      tmp.employeeBankNum = !!Number(employeeBankNum);
      tmp.employeeBankOwner = checkLength(employeeBankOwner);
      if (
        tmp.employeeJuminNum &&
        tmp.employeeEmail &&
        tmp.employeeAddr1 &&
        tmp.employeeAddr2 &&
        tmp.employeeBankCode &&
        tmp.employeeBankNum &&
        tmp.employeeBankOwner
      )
        tmp.is = true;
      setValidData(tmp);
    };
    checkValid();
    // eslint-disable-next-line
  }, [formData]);
  //===========================================================================
  console.log("---> accessToken: ", accessToken);
  return (
    <>
      <div className="comn_docker" style={style}>
        <div className="comn_layer lb_ct_layer">
          <div className="mid">
            <div className="cont">
              <ul className="write_lt">
                <li>
                  <p className="title">주민등록번호</p>
                  <input
                    name="employeeJuminNum"
                    onChange={handleChangeJuminNumInput}
                    className={
                      "comn_ip wide" +
                      (validData.employeeJuminNum ? "" : " empty")
                    }
                    type="text"
                    value={formData.employeeJuminNum || ""}
                    placeholder="주민등록번호를 입력해 주세요"
                  />
                </li>
                <li>
                  <p className="title">이메일</p>
                  <input
                    name="employeeEmail"
                    onChange={handleChangeInput}
                    className={
                      "comn_ip wide" + (validData.employeeEmail ? "" : " empty")
                    }
                    type="text"
                    value={formData.employeeEmail || ""}
                    placeholder="employee@salida.biz"
                  />

                  <div className="cert-guide-txt">
                    - 근로계약서와 급여명세서가 이메일로 전송됩니다. <br />-
                    이메일이 없을 경우 "없음"을 입력하세요. <br /> - 그럼
                    카톡으로 전송해 드립니다.
                  </div>
                </li>
                <li>
                  <p className="title">주소</p>
                  <input
                    name="employeeAddr1"
                    onChange={handleChangeInput}
                    className={
                      "comn_ip wide" + (validData.employeeAddr1 ? "" : " empty")
                    }
                    type="text"
                    value={formData.employeeAddr1 || ""}
                    placeholder="예)한누리대로 411, 국립중앙박물관 "
                  />

                  <span className="search-addr-layer">
                    <Link onClick={() => handleChangeModal(TYPE.ADDR, true)}>
                      <img src="../img/search_icon.svg" width={20} alt="" />
                    </Link>
                  </span>

                  <Modal
                    isOpen={modalIsOpen.address}
                    onRequestClose={() => handleChangeModal(TYPE.ADDR, false)}
                  >
                    <AddressPopup
                      $app={$app}
                      address1={formData.employeeAddr1}
                      onSelectAddress={handleSelectAddress}
                      onRequestClose={() => handleChangeModal(TYPE.ADDR, false)}
                    />
                  </Modal>
                  <span className="labor-wirte-gab-box"></span>
                  <input
                    name="employeeAddr2"
                    onChange={handleChangeInput}
                    className={
                      "comn_ip wide" + (validData.employeeAddr2 ? "" : " empty")
                    }
                    type="text"
                    value={formData.employeeAddr2 || ""}
                    placeholder="나머지주소를 입력해주세요"
                  />
                </li>
                <li>
                  <p className="title">입금계좌</p>
                  <input
                    name="employeeBankCode"
                    onClick={() => handleChangeModal(TYPE.BANK, true)}
                    className={
                      "comn_ip wide" +
                      (validData.employeeBankCode ? "" : " empty")
                    }
                    type=""
                    readOnly
                    value={
                      getLabelByCode(
                        BANK_TYPE_OPTIONS,
                        formData.employeeBankCode
                      ) || ""
                    }
                    placeholder="은행을 선택해 주세요"
                  />
                  <Modal
                    isOpen={modalIsOpen.bankCode}
                    onRequestClose={() => handleChangeModal(TYPE.BANK, false)}
                  >
                    <BankCodeSelectPopup
                      $app={$app}
                      formData={formData}
                      setFormData={setFormData}
                      onRequestClose={() => handleChangeModal(TYPE.BANK, false)}
                    />
                  </Modal>

                  <span className="labor-wirte-gab-box"></span>
                  <input
                    name="employeeBankNum"
                    onChange={handleChangeInput}
                    className={
                      "comn_ip wide" +
                      (validData.employeeBankNum ? "" : " empty")
                    }
                    type="number"
                    value={formData.employeeBankNum || ""}
                    placeholder="계좌번호를 입력해 주세요"
                  />
                  <span className="labor-wirte-gab-box"></span>
                  <input
                    name="employeeBankOwner"
                    onChange={handleChangeInput}
                    className={
                      "comn_ip wide" +
                      (validData.employeeBankOwner ? "" : " empty")
                    }
                    type="text"
                    value={formData.employeeBankOwner || ""}
                    placeholder="예금주를 입력해 주세요"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="btt">
            {/* <!--색상변경 : on 클래스로 제어--> */}
            <div
              className={"bt_btn " + (validData.is ? " on" : "")}
              onClick={() => handleChangedNext()}
            >
              다음
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractForm;
