import { BaseHelper } from "domain/common";
import { Caller } from "./__private";

const { nfn, wc, ex } = BaseHelper;
const { dftVal, state, list, plan, daily, total, create, close, last } = Caller;

export const StoreLaborEmployeeSalaryHelper = {
  dftVal,
  state,
  ...ex(nfn, list, nfn, nfn),
  plan: wc(plan),
  daily: wc(daily),
  total: wc(total),
  create: wc(create),
  close: wc(close),
  last: wc(last),
};
