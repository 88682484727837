import React, { useEffect, useState } from "react";

import {
  StoreLaborContractHelper,
  ContractAddDocContentsComp,
  StoreAdmissionDocumentHelper,
} from "domain";
import { getOnChangeView, setOnChangeView } from "core";

function DocBoxAddDocList(props) {
  //===========================================================================
  const { $app, tecUid } = props;
  const [data, setData] = useState({});
  const [admissionDoc, setAdmissionDoc] = useState([]);
  const reloadView = getOnChangeView();
  //===========================================================================
  const loadDoc = (docData) => {
    if (docData[0]?.mstUid) {
      const params = { mstUids: [docData[0]?.mstUid], tecUid: tecUid };
      StoreAdmissionDocumentHelper.listWithImage($app, params, (resData) => {
        setAdmissionDoc(
          resData.filter((doc) => doc.use === 100 && doc.del === 200)
        );
      });
    }
  };
  //===========================================================================
  useEffect(() => {
    if (tecUid) {
      StoreLaborContractHelper.one($app, { tecUid: tecUid }, (resData) => {
        setData([resData]); // api가 1인용이라 배열화 시킴
        loadDoc([resData]);
      });
    }
  }, [tecUid]);
  useEffect(() => {
    if (reloadView === "reloadAddDoc") {
      loadDoc(data);
      setOnChangeView("");
    }
  }, [reloadView]);

  //===========================================================================
  return (
    <>
      <ContractAddDocContentsComp
        $app={$app}
        tecUid={tecUid}
        docList={admissionDoc}
        isInDocumentBox={true}
      />
    </>
  );
}

export default DocBoxAddDocList;
