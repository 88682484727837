import { BaseHelper } from "domain/common";
import { Caller } from "./__private";

const { nfn, wc, ex } = BaseHelper;
const { dftVal, state, add, update, one, completed, end, list } = Caller;

export const StoreLaborContractHelper = {
  dftVal,
  state,
  ...ex(add, list, update, nfn),
  one: wc(one),
  completed: wc(completed),
  end: wc(end),
};
