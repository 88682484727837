import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { useRecoilValue } from "recoil";

// import { selectedStore, comma } from "core";
import { getThisMonth, comma } from "core";
// import { AuthorityHelper, StoreLaborEmployeeSalaryHelper } from "domain";

function LaborPayrollManagerWidget(props) {
  //===========================================================================
  const { $app, onChangeView = () => {}, viewOptions = {} } = props;
  // const logined = useRecoilValue(AuthorityHelper.state);
  // const store = selectedStore(logined);
  const [data, setData] = useState([]);

  //===========================================================================
  // useEffect(() => {
  //   if (store.uid) {
  //     const params = { mstUids: store.uid };
  //     StoreLaborEmployeeSalaryHelper.plan($app, params, setData);
  //   }
  // }, [logined]);
  //===========================================================================
  const EmployeeListComp = useMemo(() => {
    let count = 0;
    let amountTot = 0;
    data.forEach((item) => {
      count += 1;
      amountTot += item.amountTot;
    });

    return (
      <div className="box stub_packing bb_gr_blue">
        <Link
          onClick={() => {
            onChangeView({ view: viewOptions.PAYROLL });
          }}
          className="link"
        >
          <div className="title_box">
            <span className="sub_txt">급여 내역</span>
            <p className="title">{getThisMonth()}</p>
            <span className="guide">
              그동안 지급받은 급여내역을 확인 할 수 있습니다.
            </span>
          </div>
          <ul className="employee_stub_lt">
            <li className="item bg-white">
              {amountTot > 0 ? (
                <>
                  <span className="sub_txt">이달 급여</span>
                  <p className="value">
                    <span className="impact">{comma(amountTot)}</span>
                    <span className="index">원</span>
                  </p>
                </>
              ) : (
                <span className="prev-payroll">
                  아직 이달 급여가 정산되지 않았습니다.
                </span>
              )}
            </li>
          </ul>
        </Link>
      </div>
    );
  }, [data]);

  //===========================================================================

  return <>{EmployeeListComp}</>;
}

export default LaborPayrollManagerWidget;
