export const checkLength = (str, len) => {
  if (str) {
    if (typeof len !== "number") len = 0;
    return str.trim().length > len;
  } else return false;
};

export const checkRange = (t, min, max) => {
  try {
    t = t * 1;
  } catch {
    return false;
  }
  if (min <= t && t <= max) return true;
  else return false;
};
