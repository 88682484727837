import { BaseHelper } from "domain/common";
import { Caller } from "./__private";

const { nfn, ex, wc } = BaseHelper;
const { dftVal, state, add, update, list, listWithImage } = Caller;

export const StoreAdmissionDocumentHelper = {
  dftVal,
  state,
  ...ex(add, list, update, nfn),
  listWithImage: wc(listWithImage),
};
