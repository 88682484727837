import { atom } from "recoil";
import { get, post, put } from "core";

const defaultValue = [];

export const Caller = {
  //===========================================================================
  dftVal: defaultValue,
  state: atom({
    key: "admissionDocumentsState",
    default: defaultValue,
  }),
  //===========================================================================
  // 입사서류 등록, name, del, mstUid
  add: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/store/admission/documents", tmp);
      },
      sfn: ({ data }) => sfn(data.sads, data),
      ffn: ({ status }) => ffn(status),
    });
  },
  // 수정, uid, name, del, mstUid
  update: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await put("/api/v1/store/admission/documents", tmp);
      },
      sfn: ({ data }) => sfn(data.sads, data),
      ffn: ({ status }) => ffn(status),
    });
  },
  //===========================================================================
  // 입사서류 리스트,  mstUids
  list: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await get("/api/v1/store/admission/documents", tmp);
      },
      sfn: ({ data }) => sfn(data.sads, data),
      ffn: ({ status }) => ffn(status),
    });
  },
  // 입사서류 리스트(이미지포함),  mstUids, tecUid
  listWithImage: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await get("/api/v1/store/admission/documents/with/image", tmp);
      },
      sfn: ({ data }) => {
        data.sads.forEach((item) => {
          item.files = item.timUids.substring(1).split(" ");
        });
        sfn(data.sads, data);
      },
      ffn: ({ status }) => ffn(status),
    });
  },
};
