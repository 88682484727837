import React, { useRef } from "react";
import { atom } from "recoil";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import { AlertPopup } from "components";

import { resChk } from "core";

const busyState = atom({
  key: "busyState",
  default: false,
});

function Container({ children }) {
  //===========================================================================
  const navigate = useNavigate();
  const [busy, setBusy] = useRecoilState(busyState);
  const __refAlertPopup = useRef();
  //===========================================================================
  let callCnt = 0;
  const $app = {
    req: ({ fn, sfn, ffn = () => {}, isBusyOff = false }) => {
      if (!isBusyOff) setBusy(true);
      callCnt++;
      // console.log("callCnt in : ", callCnt);
      fn().then((res) => {
        resChk({
          res,
          success: () => {
            sfn({ data: res.data.data, navigate });
            callCnt--;
            // console.log("callCnt out : ", callCnt);
            if (!isBusyOff && callCnt === 0) setBusy(false);
          },
          failed: () => {
            ffn({ status: res.data.status, navigate });
            callCnt--;
            // console.log("callCnt out : ", callCnt);
            if (!isBusyOff && callCnt === 0) setBusy(false);
          },

          navigate,
        });
      });
    },
    setBusy: (bool) => {
      setBusy(bool);
    },
    alert: (title, message, onClickOk) => {
      __refAlertPopup.current.alert({ title, message, onClickOk });
    },
    confirm: (title, message, onClickOk) => {
      __refAlertPopup.current.confirm({ title, message, onClickOk });
    },
    custom: (title, message, custom) => {
      __refAlertPopup.current.custom({ title, message, custom });
    },
    closeModal: () => {
      __refAlertPopup.current.close();
    },
    CAMERA: navigator.camera || {},
  };
  //===========================================================================
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      $app,
    })
  );
  //===========================================================================
  return (
    <>
      <div className="clock-loader-box" style={{ display: busy ? "" : "none" }}>
        <div className="clock-loader">
          <ClockLoader size={100} loading={busy} color={"#ffcccc"} />
        </div>
      </div>

      <AlertPopup ref={__refAlertPopup} />

      {childrenWithProps}
    </>
  );
}

export default Container;
