import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import {
  DocumentBox,
  LaborMain,
  LaborPayrollList,
  LaborContractDetail,
  LaborAttendanceHistory,
} from "./__view";
import { AuthorityHelper, StoreLaborContractHelper } from "domain";

const VIEW = {
  MAIN: 100,
  PAYROLL: 200,
  DOC_BOX: 300,
  DETAIL: 400,
  ATTENDANCE: 500,
};

function LaborHomePage(props) {
  //===========================================================================
  const { $app } = props;
  const [view, setView] = useState(VIEW.MAIN);
  const [prev, setPrev] = useState(null);
  const [mstUid, setMstUid] = useState(null);
  const [mseUid, setMseUid] = useState(null);
  const accessInfo = useRecoilValue(AuthorityHelper.tokenState);
  //===========================================================================
  const handleChangeView = ({ view, prev }) => {
    setView(view);
    if (prev) setPrev(prev);
  };
  useEffect(() => {
    if (accessInfo?.tecUid) {
      StoreLaborContractHelper.one(
        $app,
        { tecUid: accessInfo.tecUid },
        (resData) => {
          setMstUid(resData.mstUid);
          setMseUid(resData.employeeUid);
        }
      );
    }
  }, [accessInfo?.tecUid]);
  //===========================================================================
  return (
    <>
      <LaborMain
        $app={$app}
        viewOptions={VIEW}
        onChangeView={handleChangeView}
        style={{ display: view === VIEW.MAIN ? "" : "none" }}
      />
      <LaborPayrollList
        $app={$app}
        mstUid={mstUid}
        mseUid={mseUid}
        tecUid={accessInfo.tecUid}
        viewOptions={VIEW}
        onChangeView={handleChangeView}
        style={{ display: view === VIEW.PAYROLL ? "" : "none" }}
      />
      <DocumentBox
        $app={$app}
        tecUid={accessInfo.tecUid}
        viewOptions={VIEW}
        onChangeView={handleChangeView}
        style={{ display: view === VIEW.DOC_BOX ? "" : "none" }}
      />
      <LaborContractDetail
        $app={$app}
        prevView={prev}
        tecUid={accessInfo.tecUid}
        viewOptions={VIEW}
        onChangeView={handleChangeView}
        style={{ display: view === VIEW.DETAIL ? "" : "none" }}
      />
      <LaborAttendanceHistory
        $app={$app}
        view={view}
        mstUid={mstUid}
        mseUid={mseUid}
        viewOptions={VIEW}
        onChangeView={handleChangeView}
        style={{ display: view === VIEW.ATTENDANCE ? "" : "none" }}
      />
    </>
  );
}

export default LaborHomePage;
