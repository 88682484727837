import React, { useEffect, useState } from "react";

function ToTheTop() {
  //===========================================================================
  const [toggleBtn, setToggleBtn] = useState(false);
  //===========================================================================
  const handleScroll = () => {
    const { scrollY } = window;
    scrollY > 300 ? setToggleBtn(true) : setToggleBtn(false);
  };
  const goToTop = () => {
    window.scrollTo({ top: 0 });
  };
  //===========================================================================
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  //===========================================================================
  return (
    <>
      <div className={"tothetop-box" + (toggleBtn ? "" : " blind")}>
        <span onClick={goToTop}>▲</span>
      </div>
    </>
  );
}

export default ToTheTop;
