import React from "react";
import { Link } from "react-router-dom";

import { HeaderArrow } from "components";

function EditCompleted(props) {
  //===========================================================================
  const { $app, style, onChangeView, viewOptions } = props;
  //===========================================================================
  return (
    <div className="comn_docker pwchk_docker" style={style}>
      <header>
        <HeaderArrow $app={$app} title="정보 수정" />
      </header>
      <div className="comn_layer userEdit_layer">
        <div className="tp">
          <i className="wide_logo"></i>
          <div className="userEdit_slog">
            <p className="slog_s">
              정보 수정이
              <br />
              완료 됐습니다!
            </p>
            <span className="sub_txt">
              더 안전하고 소중하게!
              <br />
              입력하신 정보로 수정이 완료되었습니다.
            </span>
          </div>
        </div>
        <div className="mid editSc_mid">
          <div className="cont">
            <div className="wd_right_box">
              <img src="../img/userEdit_success.png" alt="" />
            </div>
          </div>
        </div>
        <div className="btt">
          <Link to="/" className="bt_btn on">
            홈으로
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EditCompleted;
