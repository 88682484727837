import React from "react";
import { RecoilRoot } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";

import Container from "./Container";
import Routing from "./Routing";
import { ScrollToTop } from "components";

function App() {
  return (
    <RecoilRoot>
      <Router>
        <Container>
          <Routing />
        </Container>
        {/* <ScrollToTop /> */}
      </Router>
    </RecoilRoot>
  );
}

export default App;
