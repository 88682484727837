import React, { useEffect, useState } from "react";

import { HeaderOnlyArrow } from "components";
import {
  StoreNameInfo,
  StoreLaborContractHelper,
  ContractContentsComp,
  ContractSignComp,
  ContractAddDocContentsComp,
  StoreAdmissionDocumentHelper,
} from "domain";

function LaborContractDetail(props) {
  //===========================================================================
  const { $app, tecUid, style, onChangeView, viewOptions, prevView } = props;
  const [data, setData] = useState({});
  const [admissionDoc, setAdmissionDoc] = useState([]);
  //===========================================================================
  const loadDoc = (docData) => {
    if (docData.mstUid) {
      const params = { mstUids: [docData.mstUid], tecUid: tecUid };
      StoreAdmissionDocumentHelper.listWithImage($app, params, (resData) => {
        setAdmissionDoc(
          resData.filter((doc) => doc.use === 100 && doc.del === 200)
        );
      });
    }
  };
  //===========================================================================
  useEffect(() => {
    if (tecUid) {
      StoreLaborContractHelper.one($app, { tecUid: tecUid }, (resData) => {
        setData(resData);
        loadDoc(resData);
      });
    }
  }, [tecUid]);
  //===========================================================================
  return (
    <>
      <div className="comn_docker" style={style}>
        <header>
          <HeaderOnlyArrow
            onBack={() => {
              onChangeView({ view: prevView || viewOptions.DOC_BOX });
            }}
            title="근로계약 내용확인"
          />
        </header>
        <div className="comn_layer lb_ct_layer">
          <div className="mid">
            <StoreNameInfo data={data} />
            <div className="cont ctr_cont">
              <div className="ctr_info">
                <ContractContentsComp data={data} />
              </div>
              <ContractSignComp data={data} />
            </div>
          </div>

          <div className="admDoc-title"> 추가 서류 </div>

          <ContractAddDocContentsComp
            $app={$app}
            tecUid={tecUid}
            docList={admissionDoc}
            onChangeView={onChangeView}
          />
        </div>
      </div>
    </>
  );
}

export default LaborContractDetail;
