import { BaseHelper } from "domain/common";
import { Caller } from "./__private";

const { nfn, wc, ex } = BaseHelper;
const { dftVal, state, search, coordinate } = Caller;

export const CommonAddressHelper = {
  dftVal,
  state,
  ...ex(nfn, nfn, nfn, nfn),
  search: wc(search),
  coordinate: wc(coordinate),
};
