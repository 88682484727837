import { atom } from "recoil";
import { post, put, get } from "core";

const defaultValue = [];

export const Caller = {
  //===========================================================================
  dftVal: defaultValue,
  state: atom({
    key: "certificationNumberState",
    default: defaultValue,
  }),
  //===========================================================================
  send: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/certification/number/send", tmp);
      },
      sfn: ({ data }) => sfn(data.token, data),
      ffn: ({ status }) => ffn(status),
    });
  },
  check: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/certification/number/check", tmp);
      },
      sfn: ({ data }) => sfn(data.token, data),
      ffn: ({ status }) => ffn(status),
    });
  },
  //===========================================================================
};
