import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

import { CommonAddressHelper } from "domain";
import { NoData } from "components";

function Popup(props) {
  //===========================================================================
  const {
    $app,
    address1,
    onSelectAddress = () => {},
    onRequestClose = () => {},
  } = props;
  const [qs, setQs] = useState(address1);
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  //===========================================================================
  useEffect(() => {
    if (qs) handleSearchClick();
    // eslint-disable-next-line
  }, [qs]);
  //===========================================================================
  const handleSelectAddress = (address) => {
    const params = { qs: address.roadAddrPart1 };
    CommonAddressHelper.coordinate($app, params, (resData) => {
      resData?.forEach((coordinate) => {
        address.latitude = coordinate.latitude;
        address.longitude = coordinate.longitude;
      });
      onSelectAddress(address);
      onRequestClose();
    });
  };
  const handleSearchClick = (flag) => {
    CommonAddressHelper.search($app, { qs, currentPage }, (resData, resAll) => {
      let roadTot = 0;
      let jibunTot = 0;
      resData.forEach((addr) => {
        roadTot += getExistCount(addr.roadAddrPart1);
        jibunTot += getExistCount(addr.jibunAddr);
      });
      if (flag !== "more") {
        if (roadTot > jibunTot) setSelectedTab(0);
        else setSelectedTab(1);
      }
      if (currentPage === 1) setData(resData);
      else setData([...data, ...resData]);
      setTotalCount(resAll.totalCount);
    });
  };
  const handleChangeInput = (e) => {
    setQs(e.target.value);
  };
  const handleToggleClick = (tabIndex) => {
    setSelectedTab(tabIndex);
  };
  const handleMoreAddressClick = () => {
    setCurrentPage(currentPage + 1);
    handleSearchClick("more");
  };
  //===========================================================================
  const getExistCount = (str) => {
    const words = qs.split(" ");
    let count = 0;
    for (let idx in words) {
      if (str.indexOf(words[idx]) > -1) count++;
    }
    return count;
  };
  const makeHtml = (address) => {
    const words = qs.split(" ");
    let tmpHtml = address;
    for (let idx in words) {
      tmpHtml = tmpHtml.replace(words[idx], "<span>" + words[idx] + "</span>");
    }
    return tmpHtml;
  };
  //===========================================================================

  const RoadListComp = useMemo(() => {
    return (
      <ul className={selectedTab === 0 ? "" : "blind"}>
        {data.map((address, idx) => (
          <li
            key={idx}
            onClick={() => handleSelectAddress(address)}
            dangerouslySetInnerHTML={{
              __html: makeHtml(address.roadAddrPart1),
            }}
          ></li>
        ))}
      </ul>
    );
    // eslint-disable-next-line
  }, [data, selectedTab]);
  const LandListComp = useMemo(() => {
    return (
      <ul className={selectedTab === 1 ? "" : "blind"}>
        {data.map((address, idx) => (
          <li
            key={idx}
            onClick={() => handleSelectAddress(address)}
            dangerouslySetInnerHTML={{
              __html: makeHtml(address.jibunAddr),
            }}
          ></li>
        ))}
      </ul>
    );
    // eslint-disable-next-line
  }, [data, selectedTab]);

  const MoreAddressComp = useMemo(() => {
    return (
      <button
        className={totalCount > currentPage * 100 ? "" : "blind"}
        onClick={handleMoreAddressClick}
      >
        - 더보기 -
      </button>
    );
    // eslint-disable-next-line
  }, [totalCount, currentPage]);

  const NoResultComp = useMemo(() => {
    return (
      <div className={totalCount < 1 ? "" : "blind"}>
        <NoData
          message="현재 조회한 내역이 없습니다."
          subMessage="주소를 다시 확인해 주세요."
          displayType={400}
        />
      </div>
    );
  }, [totalCount]);

  //===========================================================================
  return (
    <div id="user_popup">
      <div className="pop_layer bubble_pop">
        <div className="pop_cont">
          <div className="popup_header ctrl_header">
            <i className="logo"></i>
            <button className="closed_btn" onClick={onRequestClose}></button>
          </div>
          <div className="popup_header ">
            <div className="user_info">
              <div className="ip-btn_box add-padding">
                <input
                  name="address1"
                  className="comn_ip wide"
                  type="text"
                  value={qs}
                  onChange={handleChangeInput}
                />
                <button className="sm_btn" onClick={handleSearchClick}>
                  조회
                </button>
              </div>
            </div>
          </div>
          <div className="cont bg-wht">
            <div className="search-add-result-tab">
              <button
                onClick={() => handleToggleClick(0)}
                className={selectedTab === 0 ? "active" : ""}
              >
                도로명
              </button>
              <button
                onClick={() => handleToggleClick(1)}
                className={selectedTab === 1 ? "active" : ""}
              >
                지번
              </button>
              <button>{totalCount} 건</button>
            </div>
            <div className="search-add-result-list">
              {RoadListComp}
              {LandListComp}
              {MoreAddressComp}
              {NoResultComp}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
