import { BaseHelper } from "domain/common";
import { Caller } from "./__private";

const { nfn, wc, ex } = BaseHelper;
const {
  initVal,
  dftVal,
  state,
  tokenState,
  login,
  accessToken,
  requestSendCheckNum,
} = Caller;

export const AuthorityHelper = {
  initVal,
  dftVal,
  state,
  tokenState,
  ...ex(nfn, nfn, nfn, nfn),
  login: wc(login),
  accessToken: wc(accessToken),
  requestSendCheckNum: wc(requestSendCheckNum),
};
