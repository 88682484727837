import React from "react";

import { Header } from "components";
import { LaborPayrollManagerWidget } from "domain";
import { CommutingWorkWidget, DocumentBoxWidget } from "../widget";

function LaborMain(props) {
  //===========================================================================
  const { $app, style, onChangeView = () => {}, viewOptions = {} } = props;
  //===========================================================================
  return (
    <>
      <div className="comn_docker " style={style}>
        <header>
          <Header />
        </header>
        <div className="comn_layer nav-in_layer">
          <div className="widget_wrapper">
            <LaborPayrollManagerWidget
              $app={$app}
              onChangeView={onChangeView}
              viewOptions={viewOptions}
            />
          </div>
          {/* <div className="widget_wrapper col col-3">
            <LaborEmploymentContract
              $app={$app}
              onChangeView={onChangeView}
              viewOptions={viewOptions}
            />
          </div> */}
          <div className="widget_wrapper col col-2">
            <DocumentBoxWidget
              $app={$app}
              onChangeView={onChangeView}
              viewOptions={viewOptions}
            />
            <CommutingWorkWidget
              $app={$app}
              onChangeView={onChangeView}
              viewOptions={viewOptions}
            />
          </div>
          {/* <div className="txt_bb_wrapper">
            <LaborDocumentsIssuance
              $app={$app}
              onChangedView={onChangedView}
              viewOptions={viewOptions}
            />
          </div> */}
        </div>

        {/* <FooterNav
          on={FOOTNAV_ON.LABOR}
          onChangeView={onChangeView}
          viewOptions={viewOptions}
        /> */}
      </div>
    </>
  );
}

export default LaborMain;
