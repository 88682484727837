import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Modal, HeaderOnlyArrow, NoData } from "components";
import { comma, dateFormat, getLabelByCode, EMPLOY_TYPE_OPTIONS } from "core";
import {
  StoreLaborContractHelper,
  StoreLaborEmployeeSalaryHelper,
} from "domain";
import { LaborPayStubDetail } from "../__private";

function LaborPayrollList(props) {
  //===========================================================================
  const {
    $app,
    style,
    tecUid,
    mstUid,
    mseUid,
    onChangeView = () => {},
    viewOptions,
  } = props;
  const [data, setData] = useState();
  const [info, setInfo] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  //===========================================================================
  const handleChangeSelected = (open, item) => {
    setSelectedItem(item);
    setModalIsOpen(open);
  };
  const getAmountMonth = (day) => {
    let date = new Date(day);
    let month = date.getMonth();
    if (month === 0) month = 12;
    return month;
  };
  const handleChangeView = () => {
    if (info?.employeeName) {
      onChangeView({
        view: viewOptions.DETAIL,
        prev: viewOptions.PAYROLL,
        tecUid: info.uid,
      });
    }
  };
  //===========================================================================
  useEffect(() => {
    if (mstUid && mseUid) {
      StoreLaborEmployeeSalaryHelper.list(
        $app,
        { mstUid: mstUid, mseUid: mseUid },
        setData
      );
    }
    if (tecUid) {
      StoreLaborContractHelper.one($app, { tecUid: tecUid }, setInfo);
    }
    // eslint-disable-next-line
  }, [mseUid, tecUid]);

  //===========================================================================
  return (
    <>
      <div className="comn_docker nav-in_docker" style={style}>
        <header>
          <HeaderOnlyArrow
            $app={$app}
            onBack={() => onChangeView({ view: viewOptions.MAIN })}
            title="급여 내역"
          />
        </header>
        <div className="comn_layer lb_st_layer nav-in_layer st_view_layer">
          <div className="mid">
            <div className="st_lt_box">
              <div className="worker">
                <Link onClick={() => handleChangeView()}>
                  <ul className="st_lt_box">
                    <li className="worker">
                      <p className="link"></p>
                      <div className="wk_box">
                        <i className="thumb df_user_icon"></i>

                        {info?.employeeName ? (
                          <div className="info">
                            <p className="name">
                              <span>{info?.employeeName}</span>
                            </p>
                            <p className="role">
                              <span>
                                {info?.storeName} {info?.storeChainName}
                              </span>

                              <span>
                                {getLabelByCode(
                                  EMPLOY_TYPE_OPTIONS,
                                  info?.employmentType
                                )}
                              </span>
                            </p>
                          </div>
                        ) : (
                          <> 직원이 선택되지 않았습니다. </>
                        )}
                      </div>
                    </li>
                  </ul>
                </Link>
              </div>
            </div>

            <div className="sl_packing">
              {data?.length < 1 || data === undefined ? (
                <NoData message="해당 내역이 없습니다." />
              ) : (
                <></>
              )}

              <ul className="ot_details br8">
                {data?.map((item, idx) => {
                  return (
                    <li
                      className="ot-item"
                      key={idx}
                      onClick={() => handleChangeSelected(true, item)}
                    >
                      <div className="info-line">
                        <span className="info">
                          {dateFormat(item.payDate, "YY.MM.DD")}
                        </span>
                        <span className="info">
                          {getAmountMonth(item.payDate)}월 근로분
                        </span>
                        <span className="info pay">{comma(item.amount)}</span>
                      </div>
                      {/* <div className="info-line">
                        <span className="info"> </span>
                        <span className="info overtime">추가수당</span>
                        <span className="info ot-pay">230,000</span>
                      </div> */}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => handleChangeSelected(false, {})}
        >
          <LaborPayStubDetail
            $app={$app}
            info={info}
            data={selectedItem}
            onRequestClose={() => handleChangeSelected(false, {})}
          />
        </Modal>
      </div>
    </>
  );
}

export default LaborPayrollList;
