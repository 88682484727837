import { HOSTNAME } from "core";
import React, { useEffect, useState } from "react";

function ContractAddDocForm(props, ref) {
  //===========================================================================
  const {
    selectedDoc = {},
    onRequestClose = () => {},
    onSubmitFiles = () => {},
  } = props;
  const [docFiles, setDocFiles] = useState([{}]); // 로칼이미지 파일들.
  //===========================================================================
  const handleSubmitClick = () => {
    onSubmitFiles(docFiles);
    onRequestClose();
  };
  const adddocFile = () => {
    if (docFiles.length < 5) setDocFiles([...docFiles, {}]);
  };
  const removedocFile = () => {
    if (docFiles.length !== 1)
      setDocFiles(docFiles.filter((item, idx) => idx !== docFiles.length - 1));
  };
  const handleChangeFile = (e, idx) => {
    if (e.target.files.length > 0) {
      encodeFileToBase64(e.target.files[0], idx);
    }
  };
  const encodeFileToBase64 = (fileBlob, idx) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        let tmp = [...docFiles];
        tmp[idx] = { file: fileBlob, imgSrc: reader.result };
        setDocFiles(tmp);
        resolve();
      };
    });
  };
  //===========================================================================
  useEffect(() => {
    if (selectedDoc.files && selectedDoc.files.length > 0) {
      setDocFiles(selectedDoc.files);
    } else if (selectedDoc.imgUids && selectedDoc.imgUids.length > 0) {
      setDocFiles(selectedDoc.imgUids);
    } else {
      setDocFiles([{}]);
    }
  }, [selectedDoc]);
  //===========================================================================
  return (
    <>
      <div className="pop_layer pop_bt_layer">
        <div className="pop_bt_cont bg-wht">
          <div className="popup_header">
            <span className="title">{selectedDoc.name} 등록하기 </span>
            <span className="closed_btn" onClick={onRequestClose}></span>
          </div>
          <div className="popup_mid">
            <ul className="write_lt">
              {docFiles.map((img, idx) => (
                <li key={idx}>
                  <label
                    htmlFor={"file-upload-" + idx}
                    className="doc-file-upload"
                  >
                    <span className="item img">
                      {img.imgSrc && (
                        <img
                          src={img.imgSrc}
                          alt="preview-img"
                          style={{ width: "100%" }}
                        />
                      )}
                      {typeof img === "string" && (
                        <img
                          src={`${HOSTNAME}/api/v1/images/${img}/img.png`}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      )}
                    </span>
                    <span className="item label">
                      {selectedDoc.name} 등록 {idx > 0 ? idx + 1 : ""}
                    </span>
                    <input
                      id={"file-upload-" + idx}
                      type="file"
                      className="doc-file-upload"
                      onChange={(e) => handleChangeFile(e, idx)}
                    />
                  </label>
                </li>
              ))}

              <li>
                <div className="doc-plus-btn">
                  <span onClick={adddocFile} className="plus-minus-btn">
                    <img src="../img/plus_icon.svg" />
                  </span>
                  <span
                    onClick={removedocFile}
                    className={
                      "plus-minus-btn" + (docFiles.length < 2 ? " blind" : "")
                    }
                  >
                    <img src="../img/minus_icon.svg" />
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="popup_ft">
            <div className="btn_packing">
              <button
                className={"sm_wide_btn bt_btn" + (docFiles[0] ? " on" : "")}
                onClick={handleSubmitClick}
              >
                적용하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractAddDocForm;
