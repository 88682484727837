import React from "react";
import { Link } from "react-router-dom";

function CommutingWorkWidget(props) {
  //===========================================================================
  const { onChangeView, viewOptions } = props;
  //===========================================================================
  return (
    <>
      <div className="box bb_box rectangle bb_gr_blue">
        <Link
          onClick={() => {
            onChangeView({ view: viewOptions.ATTENDANCE });
          }}
          className="link"
        ></Link>
        <div className="inner ot-img">
          <div className="bb_header mb_16">
            <div className="left">
              <p className="title">근태 내역</p>
            </div>
          </div>
          <img src="../img/overtime_status.svg" alt="" width={52} />
          <div className="bb_cont"></div>
        </div>
      </div>
    </>
  );
}

export default CommutingWorkWidget;
