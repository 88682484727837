import React from "react";

function ContractSignComp(props) {
  //===========================================================================
  const { data } = props;
  //===========================================================================
  return (
    <div className="ctr_sign">
      <div className="sign_item">
        <div className="info">
          <span className="title">사업주</span>

          <ul className="info_cont lt_info">
            <li>
              <span className="title">사업체명</span>
              <p>
                {data.storeName} {data.storeChainName}
              </p>
            </li>
            <li>
              <span className="title">주소</span>
              <p>
                {data.storeAddr1} {data.storeAddr2}
              </p>
            </li>
            <li className="sign_box">
              <span className="title">대표자</span>
              <div className="sign">
                <span>{data.storeOwnerName}</span>
                <div className="sign_index">
                  <span> (인)</span>
                  <div className="contract-sign">
                    {data.ownerSign ? (
                      <img
                        src={data.ownerSign}
                        alt="sign"
                        className="sign-size"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="sign_item">
        <div className="info">
          <span className="title">근로자</span>

          <ul className="info_cont lt_info">
            <li>
              <span className="title">주소</span>
              <p>
                {data.employeeAddr1} {data.employeeAddr2}
              </p>
            </li>
            <li>
              <span className="title">연락처</span>
              <p>
                {data.employeePhone?.replace(
                  /^(\d{2,3})(\d{3,4})(\d{4})$/,
                  `$1-$2-$3`
                )}
              </p>
            </li>
            <li className="sign_box">
              <span className="title">성명</span>
              <div className="sign">
                <span>{data.employeeName}</span>
                <div className="sign_index">
                  <span> (인)</span>
                  <div className="contract-sign">
                    {data.employeeSign ? (
                      <img
                        src={data.employeeSign}
                        alt="sign"
                        className="sign-size"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ContractSignComp;
