import { atom } from "recoil";
import { post, put, del } from "core";

const defaultValue = {};

export const Caller = {
  //===========================================================================
  dftVal: defaultValue,
  state: atom({
    key: "personalUserState",
    default: defaultValue,
  }),
  //===========================================================================
  join: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/users/join", tmp);
      },
      sfn: ({ data }) => sfn(data.user, data),
      ffn: ({ status }) => ffn(status),
    });
  },
  update: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await put("/api/v1/users/info", tmp);
      },
      sfn: ({ data }) => sfn(data.user, data),
      ffn: ({ status }) => ffn(status),
    });
  },

  // pw만 보냄 , 참이면 데이터 반환, 거짓이면 null 반환
  check: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/users/check/pw", tmp);
      },
      sfn: ({ data }) => sfn(data.user, data),
      ffn: ({ status }) => ffn(status),
    });
  },

  quit: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await del("/api/v1/users", tmp);
      },
      sfn: ({ data }) => sfn(data.user, data),
      ffn: ({ status }) => ffn(status),
    });
  },
};
