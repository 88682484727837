//=============================================================================
// Domain UID 목록
//=============================================================================
export const SA_UID = "mdo28ad09cf332a46959f0a4772e49dc725";
export const CO_UID = "mdo7c659e46e3054e189c930b7f6ef48c49";
export const SU_UID = "mdo9089d01152864ad19359e4ed172dadc7";
//=============================================================================

export const getCodeLabel = (collection, value) => {
  const selected = collection.filter((item) => item.value === value);
  if (selected.length > 0) {
    return selected[0].label;
  } else {
    return "";
  }
};

export const POS_TYPE_OPTIONS = [
  { label: "EASYPOS", value: 100 },
  { label: "OKPOS", value: 200 },
  { label: "배민", value: 300 },
];

export const COLLECT_TYPE_OPTIONS = [
  { label: "EASYPOS", value: 100 },
  { label: "OKPOS", value: 200 },
  { label: "배민", value: 300 },
  { label: "쿠팡이츠", value: 400 },
  { label: "요기요", value: 500 },
];

export const SERVER_OS_OPTIONS = [
  { label: "macOS.10.15.7", value: 100 },
  { label: "centOS.7", value: 200 },
];

export const BATCH_WORKER_STATUS = [
  { label: "대기중", value: 100 },
  { label: "준비", value: 200 },
  { label: "작업중", value: 300 },
];

export const BATCH_RESULT_TYPE = [
  { label: "시작", value: 100 },
  { label: "성공", value: 200 },
  { label: "실패", value: 300 },
];

export const PROCESS_OPTIONS = [
  { label: "IN", value: 100 },
  { label: "OUT", value: 200 },
];

export const USE_OPTIONS = [
  { label: "YES", value: 100 },
  { label: "NO", value: 200 },
];

export const RES = { YES: 100, NO: 200 };

export const TOTAL_VIEW = {
  ALL: "ALL",
  ONE: "ONE",
};

export const FOOTNAV_ON = {
  HOME: 100,
  SALE: 200,
  PURCHASE: 300,
  LABOR: 400,
};

export const CHIT_TYPE_OPTIONS = [
  { label: "월세", value: 100 },
  { label: "세금계산서", value: 200 },
  { label: "계산서", value: 300 },
  { label: "수수료", value: 400 },
  { label: "전기", value: 500 },
  { label: "가스", value: 600 },
  { label: "수도", value: 700 },
];

export const CHIT_TYPE = {
  RENT: 100,
  TAX: 200,
  RECEIPT: 300,
  FEE: 400,
};

export const FIXED_COST_OPTIONS = [
  { label: "전기", value: 500 },
  { label: "가스", value: 600 },
  { label: "수도", value: 700 },
];

export const CS_TYPE_OPTIONS = [
  { label: "매출", value: 100 },
  { label: "매입", value: 200 },
  { label: "노무", value: 300 },
  { label: "기타", value: 400 },
];

export const BANK_TYPE_OPTIONS = [
  { label: "산업은행", value: "002" },
  { label: "기업은행", value: "003" },
  { label: "KB국민은행", value: "004" },
  { label: "수협은행", value: "007" },
  { label: "수출입은행", value: "008" },
  { label: "NH농협은행", value: "011" },
  { label: "농․축협", value: "012" },
  { label: "우리은행", value: "020" },
  { label: "SC제일은행", value: "023" },
  { label: "한국씨티은행", value: "027" },
  { label: "대구은행", value: "031" },
  { label: "부산은행", value: "032" },
  { label: "광주은행", value: "034" },
  { label: "제주은행", value: "035" },
  { label: "전북은행", value: "037" },
  { label: "경남은행", value: "039" },
  { label: "우리카드", value: "041" },
  { label: "외환카드", value: "044" },
  { label: "새마을금고", value: "045" },
  { label: "신협", value: "048" },
  { label: "저축은행", value: "050" },
  { label: "모건스탠리은행", value: "052" },
  { label: "HSBC은행", value: "054" },
  { label: "도이치은행", value: "055" },
  { label: "제이피모간체이스은행", value: "057" },
  { label: "미즈호은행", value: "058" },
  { label: "엠유에프지은행", value: "059" },
  { label: "BOA은행", value: "060" },
  { label: "비엔피파리바은행", value: "061" },
  { label: "중국공상은행", value: "062" },
  { label: "중국은행", value: "063" },
  { label: "산림조합중앙회", value: "064" },
  { label: "대화은행", value: "065" },
  { label: "교통은행", value: "066" },
  { label: "중국건설은행", value: "067" },
  { label: "우체국", value: "071" },
  { label: "하나은행", value: "081" },
  { label: "신한은행", value: "088" },
  { label: "케이뱅크", value: "089" },
  { label: "카카오뱅크", value: "090" },
  { label: "토스뱅크", value: "092" },
  { label: "대신저축은행", value: "102" },
  { label: "에스비아이저축은행", value: "103" },
  { label: "에이치케이저축은행", value: "104" },
  { label: "웰컴저축은행", value: "105" },
  { label: "신한저축은행", value: "106" },
];

export const EMPLOY_TYPE_OPTIONS = [
  { label: "정규직", value: 100 },
  { label: "아르바이트", value: 200 },
  { label: "일용직", value: 300 },
];
export const EMPLOY_TYPE = { FULL_TIME: 100, PART_TIME: 200, DAY_JOB: 300 };

export const NUM_OF_WORK_OPTIONS = [
  { label: "5인 미만", value: 100 },
  { label: "5인 이상", value: 200 },
];

export const DAY_OFF_OPTIONS = [
  { label: "월", value: 100 },
  { label: "화", value: 200 },
  { label: "수", value: 300 },
  { label: "목", value: 400 },
  { label: "금", value: 500 },
  { label: "토", value: 600 },
  { label: "일", value: 700 },
];

export const WORK_WEEK_OPTIONS = [
  {
    label: "월",
    value: 100,
    working: 100,
    startTime: "09:00",
    endTime: "18:00",
  },
  {
    label: "화",
    value: 200,
    working: 100,
    startTime: "09:00",
    endTime: "18:00",
  },
  {
    label: "수",
    value: 300,
    working: 100,
    startTime: "09:00",
    endTime: "18:00",
  },
  {
    label: "목",
    value: 400,
    working: 100,
    startTime: "09:00",
    endTime: "18:00",
  },
  {
    label: "금",
    value: 500,
    working: 100,
    startTime: "09:00",
    endTime: "18:00",
  },
  {
    label: "토",
    value: 600,
    working: 200,
    startTime: "09:00",
    endTime: "18:00",
  },
  {
    label: "일",
    value: 700,
    working: 200,
    startTime: "09:00",
    endTime: "18:00",
  },
];

export const SALARY_TYPE_OPTIONS = [
  { label: "연봉", value: 100 },
  { label: "시급", value: 200 },
  { label: "일급", value: 300 },
];
export const SALARY_TYPE = { MONTHLY: 100, HOURLY: 200, DAILY: 300 };

export const PAY_TYPE_OPTIONS = [
  { label: "매월", value: 100 },
  { label: "매주", value: 200 },
  { label: "일일", value: 300 },
];
export const PAY_TYPE = { MONTHLY: 100, WEEKLY: 200, DAILY: 300 };

export const DAILY_PAY_OPTIONS = [
  { label: "당일", value: 800 },
  { label: "다음날", value: 900 },
];

export const PAY_METHOD_OPTIONS = [
  { label: "계좌이체", value: 100 },
  { label: "직접지급", value: 200 },
];
export const PAY_METHOD = { TRANSFER: 100, DIRECT: 200 };

export const EXTRA_PAY_OPTIONS = [
  { label: "식대", value: 4100 },
  { label: "교통(유류)비", value: 4200 },
  { label: "복리후생비", value: 4300 },
  { label: "직접입력", value: 4999 },
]; // 4000번대 넘기지 말것

export const SOCIAL_INSURANCE_SCHEMES = [
  { label: "국민연금", value: 100 },
  { label: "건강보험", value: 200 },
  { label: "산재보험", value: 300 },
  { label: "고용보험", value: 400 },
];

export const CONTRACT_STATE_OPTIONS = [
  { label: "전체", value: 0 },
  { label: "임시저장", value: 100 },
  { label: "서명대기", value: 200 },
  { label: "서명완료", value: 300 },
];

export const HOUR_OPTIONS = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
];
export const MINUTE_OPTIONS = [
  { label: "00", value: 0 },
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
  { label: "50", value: 50 },
];
export const OVERTIME_TYPE_OPTIONS = [
  { label: "연장근무", value: 100 },
  { label: "야간근무", value: 200 },
  { label: "휴일근무", value: 300 },
];

export const IMAGE_ADD_METHOD = {
  CAMERA: 100,
  ALBUM: 200,
};

export const IMAGE_LIST_VIEW = {
  NORMAL: 100,
  ZOOM: 200,
  EDIT: 300,
};

export const MONTHLY_AMOUNT_TYPE = [
  { label: "선불", value: 100 },
  { label: "후불", value: 200 },
];
