import React, { useState } from "react";

import { HeaderArrow } from "components";
import { DocBoxContractList, DocBoxAddDocList } from "../__private";

const BOX_TAB = [
  { label: "내문서", value: 100 },
  { label: "추가서류", value: 200 },
];

function DocumentBox(props) {
  //===========================================================================
  const { $app, tecUid, onChangeView, viewOptions, style } = props;
  const [activeTab, setActiveTab] = useState(BOX_TAB[0]);
  //===========================================================================
  const handleTabClick = (tabValue) => {
    setActiveTab(tabValue);
  };
  //===========================================================================
  return (
    <>
      <div className="comn_docker" style={style}>
        <header>
          <HeaderArrow
            onBack={() => {
              onChangeView({ view: viewOptions.MAIN });
            }}
            title="문서함"
          />
        </header>
        <div className="comn_layer">
          <div className="common-tab-box">
            <ul className="common-tab">
              {BOX_TAB.map((tab) => (
                <li
                  key={tab.value}
                  onClick={() => handleTabClick(tab)}
                  className={activeTab.value === tab.value ? " activeTab" : ""}
                >
                  {tab.label}
                </li>
              ))}
            </ul>
          </div>
          <div className={activeTab.value === 100 ? "mid" : " blind"}>
            {activeTab.comp}
            <DocBoxContractList
              $app={$app}
              tecUid={tecUid}
              onChangeView={onChangeView}
              viewOptions={viewOptions}
            />
          </div>
          <div className={activeTab.value === 200 ? "mid" : " blind"}>
            <DocBoxAddDocList
              $app={$app}
              tecUid={tecUid}
              onChangeView={onChangeView}
              viewOptions={viewOptions}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentBox;
