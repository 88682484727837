import { atom } from "recoil";
import { post, setAuth } from "core";

const initValue = {
  id: "",
  name: "",
  domainJson: [],
  rk: "",
  pkm2: "",
  pke2: "",
  stores: [],
};

const defaultValue = () => {
  let stores = JSON.parse(sessionStorage.getItem("stores") || "[]");
  stores.forEach((store) => {
    store.accounts = JSON.parse(store?.accountJson || "{}");
    store.users = JSON.parse(store.userJson);
  });
  return {
    id: sessionStorage.getItem("id") || "",
    name: sessionStorage.getItem("name") || "",
    domainJson: JSON.parse(sessionStorage.getItem("domainJson") || "[]"),
    rk: sessionStorage.getItem("rk") || "",
    pkm2: sessionStorage.getItem("pkm2") || "",
    pke2: sessionStorage.getItem("pke2") || "",
    stores,
  };
};

export const Caller = {
  //===========================================================================
  initVal: initValue,
  dftVal: defaultValue(),
  state: atom({
    key: "loginedState",
    default: defaultValue(),
  }),
  tokenState: atom({
    key: "accessTokenState",
    default: {},
  }),
  //===========================================================================
  login: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/auth/login", tmp);
      },
      sfn: ({ data, navigate }) => {
        if (params.remember) sessionStorage.setItem("id", params.id);
        else sessionStorage.setItem("id", "");
        setAuth(data.auth);
        if (data.auth.domainJson)
          data.auth.domainJson = JSON.parse(data.auth.domainJson);
        if (data.auth.stores) {
          data.auth.stores.forEach((store) => {
            store.accounts = JSON.parse(store?.accountJson || "{}");
            store.users = JSON.parse(store.userJson);
          });
        }
        sfn(data.auth, data);
        navigate("/");
      },
      ffn: ({ status }) => ffn(status),
    });
  },
  //===========================================================================
  accessToken: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/access/token", tmp);
      },
      sfn: ({ data }) => sfn(data.token, data),
      ffn: ({ status }) => ffn(status),
    });
  },
  //===========================================================================
};
