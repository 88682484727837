import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useRecoilValue } from "recoil";

import { HeaderOnlyArrow, Modal } from "components";
import { ContractSign } from "../__private/support";
import {
  StoreLaborContractHelper,
  StoreAdmissionDocumentHelper,
  ContractContentsComp,
  ContractAddDocComp,
} from "domain";

// const tecUid = "tec1500cf3161dc4237a92b4075c164fe65";

function ContractDetail(props) {
  //===========================================================================
  const {
    $app,
    tecUid,
    contractData,
    style,
    onChangeView,
    viewOptions,
    prevView,
  } = props;
  const [formData, setFormData] = useState({});
  const [admissionDoc, setAdmissionDoc] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //===========================================================================
  const handleChangeModal = (open) => {
    setModalIsOpen(open);
  };
  const handleCancelButton = () => {
    $app.closeModal();
  };
  const handleConfirmButton = () => {
    handleChangeModal(true);
    $app.closeModal();
  };

  const showConfirmAlert = () => {
    const docList = admissionDoc.map((item) => item.name + ", ");
    $app.custom(
      "계약전송 및 추가서류 알림",
      <span>
        계약이 전송되었습니다. <br /> 추가서류를 등록 하시겠습니까? <br />
        필요한 추가서류는 <br /> {docList} 입니다.
      </span>,
      () => (
        <div className="alert-btn-flex">
          <Link
            to="/labor"
            className="item cancel"
            onClick={handleCancelButton}
          >
            취소
          </Link>
          <Link className="item confirm" onClick={handleConfirmButton}>
            확인
          </Link>
        </div>
      )
    );
  };
  const completeEmployeeSign = () => {
    if (formData.employeeSign) {
      StoreLaborContractHelper.completed($app, formData, (resData) => {});
    }
  };
  const handleSubmitClick = () => {
    completeEmployeeSign();
    showConfirmAlert();
  };
  //===========================================================================
  useEffect(() => {
    if (contractData) setFormData({ ...formData, ...contractData });
  }, [contractData]);

  useEffect(() => {
    if (tecUid) {
      StoreLaborContractHelper.one($app, { tecUid: tecUid }, setFormData);
    }
  }, [tecUid]);
  useEffect(() => {
    if (formData.mstUid) {
      const params = { mstUids: [formData.mstUid], tecUid: tecUid };
      StoreAdmissionDocumentHelper.listWithImage($app, params, (resData) => {
        setAdmissionDoc(
          resData.filter((doc) => doc.use === 100 && doc.del === 200)
        );
      });
    }
  }, [formData.mstUid]);
  // useEffect(() => {
  //   if (accessToken.tecUid) {
  //     StoreLaborContractHelper.one($app, accessToken.tecUid, setformData);
  //   }
  // }, [accessToken]);
  //===========================================================================
  return (
    <>
      <div className="comn_docker" style={style}>
        <header>
          <HeaderOnlyArrow
            onBack={() => {
              onChangeView({ view: prevView || viewOptions.PAYROLL });
            }}
            title="근로계약 상세"
          />
        </header>
        <div className="comn_layer lb_ct_layer">
          <div className="mid">
            <div className="cont ctr_cont">
              <div className="ctr_info">
                <ContractContentsComp data={formData} />
              </div>
              <ContractSign formData={formData} setFormData={setFormData} />
            </div>
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => handleChangeModal(false)}
        >
          <ContractAddDocComp
            $app={$app}
            docList={admissionDoc}
            tecUid={formData.uid}
            onRequestClose={() => handleChangeModal(false)}
          />
        </Modal>

        <div className="btt">
          {/* <!--색상변경 : on 클래스로 제어--> */}
          <div className="btt two_btt">
            <span
              className={"bt_btn" + (formData.employeeSign ? " on" : "")}
              onClick={handleSubmitClick}
            >
              전송하기
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractDetail;
