import React, { useMemo } from "react";

import {
  comma,
  getLabelByCode,
  EMPLOY_TYPE_OPTIONS,
  EMPLOY_TYPE,
  WORK_WEEK_OPTIONS,
  SALARY_TYPE_OPTIONS,
  PAY_TYPE,
  PAY_TYPE_OPTIONS,
  PAY_METHOD_OPTIONS,
  PAY_METHOD,
  DAILY_PAY_OPTIONS,
  SOCIAL_INSURANCE_SCHEMES,
  BANK_TYPE_OPTIONS,
} from "core";
import { DatePicker } from "components";

function ContractContentsComp(props) {
  //===========================================================================
  const {
    data,
    setForwardData = () => {},
    isFormFlag = false,
    valid = {},
  } = props;
  //===========================================================================
  const handleChangeDate = ({ name, value }) => {
    setForwardData({ ...data, [name]: value });
  };
  //===========================================================================
  const PaydayComp = useMemo(() => {
    if (data.payday) {
      const { payType, payday } = data;
      let tmpType = getLabelByCode(PAY_TYPE_OPTIONS, payType);
      let tmpDay = getLabelByCode(WORK_WEEK_OPTIONS, payday);
      if (payType === PAY_TYPE.MONTHLY) return tmpType + " " + payday + "일";
      if (payType === PAY_TYPE.WEEKLY) return tmpType + " " + tmpDay + "요일";
      if (payType === PAY_TYPE.DAILY)
        return getLabelByCode(DAILY_PAY_OPTIONS, payday);
    }
  }, [data?.payType, data?.payday]);

  const GetWorkDayViewComp = useMemo(() => {
    const arr = data.weeklyWorkDays;
    if (Array.isArray(arr)) {
      const startTime = arr[0].startTime;
      const endTime = arr[0].endTime;
      const renderWorkingDays = arr
        .filter((item) => item.working === 100)
        .map((item, idx) => (
          <div key={idx} className="detail-workingday">
            <span>{item.label} &nbsp;</span>
            <span>{item.startTime}</span>
            <span>{"~"}</span>
            <span>{item.endTime}</span>
          </div>
        ));
      if (
        arr.some(
          (item) => item.startTime !== startTime || item.endTime !== endTime
        )
      ) {
        return <>{renderWorkingDays}</>;
      }
      return (
        <>
          <div>
            {arr.map((item) => (item.working === 100 ? item.label + " " : ""))}
          </div>
          <div>
            {startTime} {"~"} {endTime}
          </div>
        </>
      );
    }
  }, [data?.weeklyWorkDays]);
  //===========================================================================
  return (
    <ul className="ctr_cont">
      <li>
        <span className="title">계약종류</span>
        <p className="cont">
          {getLabelByCode(EMPLOY_TYPE_OPTIONS, data?.employmentType)}
        </p>
      </li>
      <li>
        <span className="title">담당업무</span>
        <p className="cont">{data?.workRespon}</p>
      </li>
      <li>
        <span className="title">근무장소</span>
        <p className="cont">{data?.workPlace}</p>
      </li>
      <li>
        <span className="title">근무일</span>
        <p>
          {data?.workStartDate} ~ {data?.workEndDate}
        </p>
      </li>
      <li>
        <span className="title">근무요일</span>
        <div className="cont">{GetWorkDayViewComp}</div>
      </li>
      <li>
        <span className="title">휴게시간</span>
        <p className="cont">
          {data?.breakStartTime} ~ {data?.breakEndTime}
        </p>
      </li>
      <li>
        <span className="title">급여</span>
        <p className="cont">
          <span className="border-b1px">
            {getLabelByCode(SALARY_TYPE_OPTIONS, data?.salaryType)}
            {": "}
            {comma(data?.salaryAmount)}원
          </span>

          {data.employmentType === EMPLOY_TYPE.FULL_TIME ? (
            <>
              <span className={data.basicSalary === 0 ? " blind" : ""}>
                월 기본급: {comma(data.basicSalary)}원
              </span>
              <span className={data.allowance === 0 ? " blind" : ""}>
                제수당: {comma(data.allowance)}원
              </span>
              {data.dailyFixedOtHour > 0 ? (
                <span>일 고정연장 {data.dailyFixedOtHour}시간 포함</span>
              ) : (
                <></>
              )}
              {data.nightWorkHour > 0 ? (
                <span>야간근무 {data.nightWorkHour}시간 포함</span>
              ) : (
                <></>
              )}
              {data.holidayWorkHour > 0 ? (
                <span>휴일근무 {data.holidayWorkHour}시간 포함</span>
              ) : (
                <></>
              )}
              {data.weeklyAllowance === 100 ? (
                <span>주휴수당 포함</span>
              ) : (
                <></>
              )}
              {data.annualAllowance === 100 ? (
                <span>연차수당 포함</span>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </p>
      </li>
      <li>
        <span className="title">급여일</span>
        <p className="cont">{PaydayComp}</p>
      </li>
      {data.extraPay?.length > 0 ? (
        <li>
          <span className="title">기타급여</span>
          <p className="cont">
            {data.extraPay?.map((exPay, idx) => {
              return (
                <span key={idx}>
                  {exPay.name} {comma(exPay.amount)}원
                </span>
              );
            })}
          </p>
        </li>
      ) : (
        <></>
      )}
      <li>
        <span className="title">지급방법</span>
        <p className="cont">
          {getLabelByCode(PAY_METHOD_OPTIONS, data?.payMethod)}
        </p>
      </li>
      {data.employeeBankNum && data.payMethod === PAY_METHOD.TRANSFER ? (
        <>
          <li>
            <span className="title">지급은행</span>
            <p className="cont">
              {getLabelByCode(BANK_TYPE_OPTIONS, data?.employeeBankCode)}
            </p>
          </li>
          <li>
            <span className="title">지급계좌</span>
            <p className="cont">{data?.employeeBankNum}</p>
          </li>
          <li>
            <span className="title">예금주</span>
            <p className="cont">{data?.employeeBankOwner}</p>
          </li>
        </>
      ) : (
        <></>
      )}
      <li>
        <span className="title">사회보험</span>
        <p className="cont">
          {getLabelByCode(SOCIAL_INSURANCE_SCHEMES, data?.socialInsurance)}
        </p>
      </li>
      {Array.isArray(data?.specialContract) && data?.specialContract[0] ? (
        <li>
          <span className="title">특약사항</span>
          <p className="cont">
            {Array.isArray(data?.specialContract) &&
              data?.specialContract.map((special, idx) => (
                <span key={idx}>
                  {idx + 1}.{special.desc}
                </span>
              ))}
          </p>
        </li>
      ) : null}
      {isFormFlag ? (
        <li>
          <span className="title">계약일</span>
          <div className="cont">
            <div className="date_box">
              <DatePicker
                name={"contractDate"}
                onChange={({ name, value }) =>
                  handleChangeDate({ name, value })
                }
                value={data.contractDate}
                placeholder={"계약일"}
                className={
                  "comn_ip wide x-shadow" + (data.contractDate ? "" : " empty")
                }
                pickerClassName={"datePicker-padding"}
              />
              <span className={"noti_txt" + (valid.msg ? " " : " blind")}>
                {valid.msg}
              </span>
            </div>
          </div>
        </li>
      ) : (
        <li>
          <span className="title">계약일</span>
          <p className="cont">{data.contractDate}</p>
        </li>
      )}
    </ul>
  );
}

export default ContractContentsComp;
