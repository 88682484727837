import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";

import { selectedStore, getPictureFromAlbum, uploadPicture } from "core";
import { AuthorityHelper } from "domain"; // StorePurchaseHelper

function PhotoAttach(props) {
  //===========================================================================
  const { $app, style, onChangeView = () => {}, viewOptions = {} } = props;
  const logined = useRecoilValue(AuthorityHelper.state);
  const initFormData = () => {
    return { fileURI: "" };
  };
  const [formData, setFormData] = useState(initFormData());
  const [selStore, setSelStore] = useState(selectedStore(logined));
  //===========================================================================
  const handleChangeView = (flag) => {
    onChangeView({ view: viewOptions.MAIN, reloadFlag: flag || 0 });
  };
  const handleClickAlbum = () => {
    if ($app.CAMERA.getPicture) {
      getPictureFromAlbum({
        $app,
        sfn: (res) => {
          console.log("---> res", JSON.stringify(res));
          const image = document.getElementById("selectedMyImageFromAlbum");
          image.src = res.fileEntryURL;
          setFormData({ ...formData, fileURI: res.fileURI });
        },
      });
    }
  };
  // const handleClickSubmit = () => {
  //   if (selStore.uid && formData.fileURI) {
  //     const params = { mstUid: selStore.uid };
  //     StorePurchaseHelper.add($app, params, (res) => {
  //       console.log("---> res", JSON.stringify(res));
  //       $app.setBusy(true);
  //       uploadPicture({
  //         $app,
  //         params: {
  //           area: "100",
  //           type: "100",
  //           refUid: res.uid,
  //         },
  //         fileURI: formData.fileURI,
  //         afterProc: ({ images }) => {
  //           console.log("---> images", JSON.stringify(images));
  //           $app.setBusy(false);
  //           handleChangeView(100);
  //         },
  //       });
  //     });
  //   } else {
  //     alert("전송할 이미지를 선택해 주세요.");
  //   }
  // };
  //===========================================================================
  return (
    <div className="pop_layer bg-gray full-pop_layer" style={style}>
      <div className="popup_header">
        <i className="logo"></i>
        <Link onClick={handleChangeView} className="closed_btn"></Link>
      </div>
      <div className="popup_cont">
        <div className="pop_guide">
          <div className="guide_txt_box">
            <p className="impact">
              {selStore.name} {selStore.chainName}
            </p>
            <p className="impact">영수증/거래명세표</p>
            <b>처음부터 끝까지</b>
            <span>잘 나온 사진을 선택해 주세요</span>
            무얼 샀는지, 어디에서 샀는지
            <span className="sub_txt">
              <br />
              상세내역까지 다 기록해드릴게요
            </span>
          </div>
        </div>
        <div className="pop_guide_image">
          <img
            onClick={handleClickAlbum}
            id="selectedMyImageFromAlbum"
            className="wide_guide"
            src="../img/popup_cam_img.svg"
            alt=""
          />
        </div>
      </div>
      <div className="btt">
        {/* <Link onClick={handleClickSubmit} className="bt_btn on">
          사진 전송하기
        </Link> */}
      </div>
    </div>
  );
}

export default PhotoAttach;
