import React, { useMemo } from "react";

import { dateFormat, minToHour } from "core";
import { NoData } from "components";

function DailyAttendanceList(props) {
  //===========================================================================
  const { data = [], workDate, filters, onChangeView, viewOptions } = props;
  //===========================================================================
  const handleChangeDetailView = (item) => {
    onChangeView({
      view: viewOptions.ATTENDANCE_CHECK,
      prev: viewOptions.ATTENDANCE_HISTORY,
      workDate: item.date,
    });
  };
  const calculatePayMins = (item) => {
    return (
      item.workNormal +
      item.workNormalOt * 1.5 +
      item.workNight * 1.5 +
      item.workNightOt * 2.0 +
      item.workHoliday * 1.5 +
      item.workHolidayOt * 2.0 +
      item.workHolidayNight * 2.0 +
      item.workHolidayNightOt * 2.5
    );
  };
  const isValidAttendance = (item) => {
    return item.start === 100 && item.end === 100;
  };
  //===========================================================================
  const DailyAttendanceListComp = useMemo(() => {
    return data.map((item, idx) => {
      return (
        <li
          className={
            filters.id !== "all" &&
            item.work.findIndex((item) => item.mseUid === filters.id) === -1
              ? " blind"
              : " item"
          }
          key={idx}
        >
          <p className="date attendance-date">
            {dateFormat(item.date, "M.D")} {item.week}
          </p>
          <ul className="lt_cont">
            {/* <li className={filters.id === "all" ? "item" : " blind"}>
              <div className="txt">
                <span className="title"> 총 근로</span>
              </div>
              <p className="hour">
                {item.dailyMins > 0 ? minToHour(item.dailyMins) : ""}

                {item.dailyPayMins - item.dailyMins > 0
                  ? "(" +
                    "+" +
                    minToHour(item.dailyPayMins - item.dailyMins) +
                    ")"
                  : " "}
              </p>
            </li> */}

            {item.work?.map((item, idx) => {
              const workMins = isValidAttendance(item) ? item.workMinutes : 0;
              const payMins = isValidAttendance(item)
                ? calculatePayMins(item)
                : 0;

              return (
                <li
                  className={
                    filters.id === "all" || filters.id === item.mseUid
                      ? " item"
                      : " blind"
                  }
                  key={idx}
                >
                  <div className="txt">
                    <span className="title name">{item.employeeName}</span>
                    <p className="sub-title">
                      <span className="item ">{item.workRespon}</span>
                    </p>
                  </div>
                  <div className="txt time txt-right">
                    <span className="hour time">
                      {workMins > 0 ? minToHour(workMins) : ""}
                      {payMins - workMins > 0
                        ? "(" + "+" + minToHour(payMins - workMins) + ")"
                        : ""}
                    </span>
                    <p className="sub-title">
                      <span className="item right-txt">
                        {item.startTime && item.start === 100
                          ? item.startTime
                          : "미체크"}
                        ~
                        {item.endTime && item.end === 100
                          ? item.endTime
                          : "미체크"}
                      </span>
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        </li>
      );
    });
  }, [data, filters, workDate, handleChangeDetailView]);
  //===========================================================================
  return (
    <>
      {data?.length > 0 ? (
        <ul className="day-summ_box">{DailyAttendanceListComp}</ul>
      ) : (
        <NoData message="현재 등록된 근태 내역이 없습니다." />
      )}
    </>
  );
}

export default DailyAttendanceList;
