import React, { useState, useEffect } from "react";
import { HOSTNAME, today, getOnChangeView, setOnChangeView } from "core";
import { StoreLaborContractHelper, StoreAdmissionDocumentHelper } from "domain";
import { NoData } from "components";

function DocBoxContractList(props) {
  //===========================================================================
  const { $app, tecUid, onChangeView, viewOptions } = props;
  const [data, setData] = useState([]);
  const [admissionDoc, setAdmissionDoc] = useState();
  const reloadView = getOnChangeView();
  //===========================================================================
  const handleChangeView = () => {
    onChangeView({
      view: viewOptions.DETAIL,
      prev: viewOptions.DOC_BOX,
      tecUid,
    });
  };
  const loadDoc = (docData) => {
    if (docData[0]?.mstUid) {
      const params = { mstUids: [docData[0]?.mstUid], tecUid: tecUid };
      StoreAdmissionDocumentHelper.listWithImage($app, params, (resData) => {
        setAdmissionDoc(
          resData.filter((doc) => doc.use === 100 && doc.del === 200)
        );
      });
    }
  };
  //===========================================================================
  useEffect(() => {
    if (tecUid) {
      StoreLaborContractHelper.one($app, { tecUid: tecUid }, (resData) => {
        setData([resData]); // api가 1인용이라 배열화 시킴
        loadDoc([resData]);
      });
    }
  }, [tecUid]);
  useEffect(() => {
    if (reloadView === "reloadAddDoc") {
      loadDoc(data);
      setOnChangeView("");
    }
  }, [reloadView]);

  //===========================================================================
  return (
    <>
      {data?.length < 1 ? (
        <NoData message="등록된 계약서가 없습니다." />
      ) : (
        <></>
      )}
      {data?.map((item, idx) => (
        <div
          className="doc-list-box bg-wht"
          key={idx}
          onClick={handleChangeView}
        >
          <div className="cell-3-flex">
            <span className="item thumb">
              {/* {first ? (
                <img
                  src={HOSTNAME + "/api/v1/images/" + first.timUid + "/img.png"}
                  alt=""
                />
              ) : ( */}
              <img src="../img/storeIcon.svg" alt="" />
              {/* )} */}
            </span>
            <span className="item">{item.storeName}</span>
            <span className="item down">
              <a
                className="doc-down"
                href={`${HOSTNAME}/api/v1/store/employee/contracts/${tecUid}/download`}
                target="_blank"
              >
                <img src="../img/doc-down.svg" />
                문서받기
              </a>
            </span>
          </div>
          <div className="doc-list-line period">
            <span className="txt-right">
              {item.workStartDate} ~{" "}
              {item.workEndDate && item.workEndDate < today()
                ? item.workEndDate
                : "근무중"}
            </span>
          </div>
          <div className="doc-list-line">
            {admissionDoc?.map((doc, idx) => (
              <span className={"doc-tag" + (doc.timUid ? " on" : "")} key={idx}>
                {doc.name}
              </span>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

export default DocBoxContractList;
