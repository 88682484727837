import {
  NotFoundPage,
  AuthorityPage,
  HomePage,
  ContractHomePage,
  LaborHomePage,
} from "domain";

const routList = [
  { path: "*", component: NotFoundPage, key: "notFound" },
  { path: "/", component: HomePage, key: "home" },
  { path: "/index.html", component: HomePage, key: "home" },
  { path: "/authority/:at", component: AuthorityPage, key: "authority" },
  { path: "/contract", component: ContractHomePage, key: "contract" },
  { path: "/labor", component: LaborHomePage, key: "labor" },

  // { path: "/login", component: LoginPage, key: "login" },
  // { path: "/member", component: EditPage, key: "member" },
  // { path: "/join", component: JoinPage, key: "join" },
];

export const RoutList = routList;
