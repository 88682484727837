import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import { AuthorityHelper } from "domain";
import { CertificationForm } from "./__private";

function AuthorityPage(props) {
  //===========================================================================
  const { $app } = props;
  const [accessInfo, setAccessInfo] = useRecoilState(
    AuthorityHelper.tokenState
  );
  const { at } = useParams();
  const navigate = useNavigate();
  //===========================================================================
  useEffect(() => {
    if (at) {
      AuthorityHelper.accessToken(
        $app,
        { accessToken: at },
        (resData) => {
          setAccessInfo({ ...resData, tecUid: at });
          // console.log("resData ===>", resData);
        },
        () => navigate("/")
      );
    }
  }, [at]);
  //===========================================================================
  return (
    <>
      <div className="comn_docker">
        <div className="comn_layer login_layer">
          <div className="cert-logo">
            <i className="wide_logo logo"></i>{" "}
            <span className="txt">통합인증센터</span>
            <h2>
              {accessInfo?.storeName + " " + accessInfo?.storeChainName} 에서 [{" "}
              {accessInfo?.targetName} ]님에게 <br />
              근로계약서 서명요청이 들어왔습니다.
              <br />
              확인을 위해 본인인증을 해주세요.
            </h2>
          </div>
          <div className="mid">
            <CertificationForm $app={$app} accessInfo={accessInfo} />
          </div>
          <div className="btt">
            <ul className="sub_txt_menu all_ct">
              <li>
                <a>이용약관</a>
              </li>
              <li>
                <a className="bold">개인정보처리방침</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthorityPage;
