import React, { useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import { RoutList } from "./RoutList";

function Routing(props) {
  //===========================================================================
  const { $app } = props;
  //===========================================================================
  const routListComp = useMemo(() => {
    return RoutList.map((item, idx) => <Route key={idx} exact path={item.path} element={<item.component $app={$app} />} />);
  }, [$app]);
  //===========================================================================
  return (
    <>
      <Routes>{routListComp}</Routes>
    </>
  );
}

export default Routing;
