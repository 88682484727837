import { atom } from "recoil";
import { get, post, put } from "core";

const defaultValue = [];

export const Caller = {
  //===========================================================================
  dftVal: defaultValue,
  state: atom({
    key: "storeLaborEmployeeSalaryState",
    default: defaultValue,
  }),
  //===========================================================================
  //급여 생성: mstUid, salaryMonth(YYYY-MM)
  create: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, { qs: "", page: 0, size: 1000 }, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/store/employee/salarys/create", tmp);
      },
      sfn: ({ data }) => sfn(data),
      ffn: ({ status }) => ffn(status),
    });
  },

  // 지급된 급여(순이익용): mstUids, startDate, endDate
  daily: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, { qs: "", page: 0, size: 1000 }, params);
    $app.req({
      fn: async () => {
        return await get("/api/v1/store/employee/salarys/daily", tmp);
      },
      sfn: ({ data }) => sfn(data.storeEmployeeSalarys, data),
      ffn: ({ status }) => ffn(status),
    });
  },
  // 이달의 급여 예상, mstUids // 2023.8.1 기준으로 안쓰게 됨
  plan: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, { qs: "", page: 0, size: 1000 }, params);
    $app.req({
      fn: async () => {
        return await get("/api/v1/store/employee/salarys/plan", tmp);
      },
      sfn: ({ data }) => sfn(data.storeEmployeeSalarys, data),
      ffn: ({ status }) => ffn(status),
    });
  },
  //특정근로자 급여목록 : mstUid, mseUid
  list: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, { qs: "", page: 0, size: 1000 }, params);
    $app.req({
      fn: async () => {
        return await get("/api/v1/store/employee/salarys", tmp);
      },
      sfn: ({ data }) => {
        data.storeEmployeeSalarys.forEach((item) => {
          item.detailsJson = JSON.parse(item.detailsJson);
        });
        sfn(data.storeEmployeeSalarys, data);
      },
      ffn: ({ status }) => ffn(status),
    });
  },

  //근로자별 지급내역 합산 : startDate, endDate, mstUids
  total: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, { qs: "", page: 0, size: 1000 }, params);
    $app.req({
      fn: async () => {
        return await get("/api/v1/store/employee/salarys/total", tmp);
      },
      sfn: ({ data }) => {
        data.storeEmployeeSalarys.forEach((item) => {
          item.mstUid = params.mstUids;
        });
        sfn(data.storeEmployeeSalarys, data);
      },
      ffn: ({ status }) => ffn(status),
    });
  },
  //급여 명세서 전송(마감): mstUid, salaryMonth(YYYY-MM)
  close: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await put("/api/v1/store/employee/salarys/close", tmp);
      },
      sfn: ({ data }) => {
        sfn(data.storeEmployeeSalarys, data);
      },
      ffn: ({ status }) => ffn(status),
    });
  },

  //마지막 지급달 조회: mstUid
  last: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await get("/api/v1/store/employee/salarys/last", tmp);
      },
      sfn: ({ data }) => sfn(data.storeEmployeeSalary),
      ffn: ({ status }) => ffn(status),
    });
  },
};
