/**
 * 현재 선택된 혹은 되어진 점포의 UID를 배열 형태로 반환.
 * @param {*} logined
 * @returns
 */
export const getMstUidsParam = (logined) => {
  let mstUids = [];
  let tmp = logined.stores.filter((store) => store.first);
  if (tmp.length > 0) mstUids = [tmp[0].uid];
  else if (logined.stores.length > 0) mstUids = [logined.stores[0].uid];
  return mstUids;
};

export const selectedStore = (logined) => {
  let selectedStore = {};
  let tmp = logined.stores.filter((store) => store.first);
  if (tmp.length > 0) selectedStore = tmp[0];
  else if (logined.stores.length > 0) selectedStore = logined.stores[0];
  return selectedStore;
};
