import { BaseHelper } from "domain/common";
import { Caller } from "./__private";

const { nfn, wc, ex } = BaseHelper;
const { dftVal, state, join, check, update, quit } = Caller;

export const PersonalUserHelper = {
  dftVal,
  state,
  ...ex(nfn, nfn, update, nfn),
  join: wc(join),
  check: wc(check),
  quit: wc(quit),
};
