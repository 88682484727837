import { BaseHelper } from "domain/common";
import { Caller } from "./__private";

const { nfn, ex, wc } = BaseHelper;
const { dftVal, state, send, check } = Caller;

export const CertificationNumberHelper = {
  dftVal,
  state,
  ...ex(nfn, nfn, nfn, nfn),
  send: wc(send),
  check: wc(check),
};
