import React from "react";
import ReactDOM from "react-dom/client";

import "./assets/css/common.css";
import "./assets/css/custom.css";
import "./assets/css/custom2.css";
import { App } from "./core";

const startApp = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

startApp();
