import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import {
  makeMonth,
  getPeriodWithYear,
  today,
  dateFormat,
  dateFormatKW,
  getTodayWeek,
} from "core";

const defaultYear = new Date().getFullYear();
const defaultMonth = new Date().getMonth() + 1;

function DatePicker(props, ref) {
  //===========================================================================
  const initMonth = () => {
    return makeMonth(defaultYear, defaultMonth);
  };
  const initFilters = () => {
    const filters = {
      year: defaultYear, // 화면용
      month: defaultMonth, // 화면용
    };
    return filters;
  };
  //===========================================================================
  const {
    name = "",
    value,
    onChange = () => {},
    placeholder,
    className,
    pickerClassName,
    pickerHeight = null,
    displayFlag = 100,
  } = props;
  const [popup, setPopup] = useState(false);
  const [month, setMonth] = useState(initMonth());
  const [filters, setFilters] = useState(initFilters());
  const [selectedDate, setSelectedDate] = useState(today());
  const [yearInputOpen, setYearInputOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const __datePickerRef = useRef(null);
  //===========================================================================
  const handleTogglePopup = () => {
    setPopup(!popup);
  };
  const handleValueChange = (e) => {
    onChange({ name, value: e.target.value });
  };
  const handleChangeYearLayerOpen = () => {
    setYearInputOpen(yearInputOpen ? false : true);
  };
  const handleChangeYearInput = (e) => {
    let tmpValue = e.target.value;
    if (tmpValue > defaultYear) tmpValue = defaultYear;
    setFormData(tmpValue);
  };
  const handleChangeYearSubmit = () => {
    if (formData > 1900) {
      setMonth(makeMonth(formData, filters.month));
      setFilters((prevState) => ({
        ...prevState,
        year: formData,
      }));
    }
    handleChangeYearLayerOpen();
  };

  const isValidDate = (value) => {
    // YYYY-MM-DD 형식 검사
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(value)) {
      return false;
    }
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return false;
    }
    return true;
  };

  const handleDisplayValue = () => {
    let tmp = value || "";
    if (displayFlag === 200) {
      tmp =
        value === today()
          ? "오늘" + " " + "(" + getTodayWeek() + ")"
          : dateFormatKW(selectedDate);
    }
    return tmp;
  };

  const handleDateClick = (ymd) => {
    setSelectedDate(ymd);
    onChange({ name, value: ymd });
    setPopup(!popup);
  };
  const checkThisMonth = (date) => {
    const pattern = /^\d{1,2}\/\d{1,2}$/;
    if (pattern.test(date)) return " greyDate";
    else return "";
  };
  const handleClickPrev = () => {
    let tmpY = filters.year * 1;
    const tmpM = filters.month * 1;
    let prevNum = tmpM - 1;
    if (prevNum <= 0) {
      tmpY = tmpY - 1;
      prevNum = 12;
    }
    const [startDate, endDate] = getPeriodWithYear(tmpY, prevNum);
    setMonth(makeMonth(tmpY, prevNum));
    setFilters((prevState) => ({
      ...prevState,
      year: startDate.substring(0, 4),
      month: prevNum,
      startDate,
      endDate,
    }));
  };

  const handleClickNext = () => {
    let tmpY = filters.year * 1;
    const tmpNum = filters.month * 1;
    let nextNum = tmpNum + 1;
    if (nextNum > 12) {
      tmpY = tmpY + 1;
      nextNum = 1;
    }
    const [startDate, endDate] = getPeriodWithYear(tmpY, nextNum);
    setMonth(makeMonth(tmpY, nextNum));
    setFilters((prevState) => ({
      ...prevState,
      year: startDate.substring(0, 4),
      month: nextNum,
      startDate,
      endDate,
    }));
  };
  //===========================================================================
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        __datePickerRef.current &&
        !__datePickerRef.current.contains(event.target) &&
        event.target.name !== name
      ) {
        setPopup(false);
      }
    };
    const handleEvent = (event) => {
      handleOutsideClick(event);
    };
    document.addEventListener("mousedown", handleEvent);
    document.addEventListener("touchstart", handleEvent);
    return () => {
      document.removeEventListener("mousedown", handleEvent);
      document.removeEventListener("touchstart", handleEvent);
    };
  }, [name]);

  useEffect(() => {
    let valueDate = "";
    if (!isValidDate(value)) {
      valueDate = new Date(today());
    } else {
      valueDate = new Date(value);
    }

    const selectedYear = valueDate.getFullYear();
    const selectedMonth = valueDate.getMonth() + 1;
    setMonth(makeMonth(selectedYear, selectedMonth));
    setFilters((prevState) => ({
      ...prevState,
      year: selectedYear, // 화면용
      month: selectedMonth, // 화면용
    }));
    setSelectedDate(dateFormat(valueDate, "YYYY-MM-DD"));
  }, [value]);

  useImperativeHandle(ref, () => ({
    clear() {
      setSelectedDate(null);
    },
  }));

  //===========================================================================
  return (
    <>
      <input
        name={name}
        value={handleDisplayValue()}
        className={className}
        placeholder={placeholder}
        onClick={handleTogglePopup}
        onChange={handleValueChange}
        readOnly
      />
      <div
        className="date-picker-cont "
        style={popup && pickerHeight ? { height: pickerHeight + "px" } : {}}
      >
        <div
          className={
            "date-picker-layer" +
            " " +
            pickerClassName +
            (popup ? "" : " blind")
          }
          //   style={{ width: size || "100%" }}
        >
          <div className="datePicker_packing" ref={__datePickerRef}>
            <div className="header ">
              <div className="month">
                <button className="left month_btn" onClick={handleClickPrev}>
                  {filters.month === 1
                    ? filters.year.toString().substring(2, 4) * 1 -
                      1 +
                      "년" +
                      12
                    : filters.month - 1}
                  월
                </button>
                <span className="month_txt" onClick={handleChangeYearLayerOpen}>
                  {filters.year.toString().substring(2, 4)}년 {filters.month}월
                </span>
                <button className="right month_btn" onClick={handleClickNext}>
                  {filters.month === 12
                    ? filters.year.toString().substring(2, 4) * 1 + 1 + "년" + 1
                    : filters.month + 1}
                  월
                </button>
              </div>
              <div className="date">
                <p className="item">일</p>
                <p className="item">월</p>
                <p className="item">화</p>
                <p className="item">수</p>
                <p className="item">목</p>
                <p className="item">금</p>
                <p className="item">토</p>
              </div>
            </div>
            <div
              className={"year-select-layer" + (yearInputOpen ? "" : " blind")}
            >
              <p className="title year-input-title">연도 입력 </p>
              <div className="input-confirm-box">
                <div className="item input">
                  <input
                    name="year"
                    onChange={handleChangeYearInput}
                    className="comn_ip wide"
                    type="number"
                    placeholder={"ex) 1970, 1988"}
                    value={formData || ""}
                    maxLength={4}
                    pattern="\d{4}"
                  />
                </div>
                <div className="item btn">
                  <button className="sm_btn" onClick={handleChangeYearSubmit}>
                    확인
                  </button>
                </div>
              </div>
            </div>
            <ul className="picker_date">
              {month.map((day, idx) => {
                return (
                  <li
                    key={idx}
                    className={
                      "item" + (day.ymd === selectedDate ? " selected-on" : "")
                    }
                    onClick={() => handleDateClick(day.ymd, idx)}
                  >
                    <span className="date">
                      <span
                        className={
                          (day.ymd === today() ? " day-on " : " ") +
                          checkThisMonth(day.d)
                        }
                      >
                        {day.d}
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(DatePicker);
