const nfn = () => {};

const wrappingCaller = (fn = () => {}) => {
  return ($app, params, sfn, ffn) => fn({ $app, params, sfn, ffn });
};

export const BaseHelper = {
  nfn,
  wc: wrappingCaller,
  ex: (c, r, u, d) => ({
    add: c ? wrappingCaller(c) : nfn,
    list: r ? wrappingCaller(r) : nfn,
    update: u ? wrappingCaller(u) : nfn,
    delete: d ? wrappingCaller(d) : nfn,
  }),
};
