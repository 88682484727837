/**
 * @param {*} pad "0000"
 * @param {*} user_str "123"
 * return "0123"
 */
export const lpad = (pad, user_str) => {
  if (typeof user_str === "undefined") return pad;
  return (pad + user_str).slice(-pad.length);
};

export const rpad = (pad, user_str) => {
  if (typeof user_str === "undefined") return pad;
  return (user_str + pad).substring(0, pad.length);
};

export const maskingString = (strName) => {
  if (strName.length > 2) {
    let originName = strName.split("");
    originName.forEach(function (name, i) {
      if (i === 0 || i === originName.length - 1) return;
      originName[i] = "*";
    });
    let joinName = originName.join();
    return joinName.replace(/,/g, "");
  } else {
    let pattern = /.$/; // 정규식
    return strName.replace(pattern, "*");
  }
};

// String.prototype.format = function () {
//   let formatted = this;
//   for (let arg in arguments) {
//     formatted = formatted.replace("{" + arg + "}", arguments[arg]);
//   }
//   return formatted;
// };

// export const effectSummary = (target, arr) => {
//   let params = [];
//   for (let i in arr) {
//     let item = arr[i];
//     if (item.min === item.max) params.push("<span class='skill-effect'>" + item.min + item.unit + "</span>");
//     else params.push("<span class='skill-effect'>" + item.min + item.unit + "->" + item.max + item.unit + "</span>");
//   }
//   return target.format(...params);
// };
