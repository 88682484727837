import React from "react";

function StoreNameInfo(props) {
  //===========================================================================
  const { data } = props;
  //===========================================================================
  return (
    <>
      <div className="store-name-info">
        <i className="thumb df_user_icon"></i>
        <ul className="info">
          <li className="name">{data.storeName + " " + data.storeChainName}</li>
          <li className="place sub_info">대표: {data.storeOwnerName}</li>
        </ul>
      </div>
    </>
  );
}

export default StoreNameInfo;
