import { right, READ_IDX } from "core";

export const clearAuth = () => {
  sessionStorage.setItem("id", "");
  sessionStorage.setItem("name", "");
  sessionStorage.setItem("domainJson", "[]");
  sessionStorage.setItem("rk", "");
  sessionStorage.setItem("pkm2", "");
  sessionStorage.setItem("pke2", "");
  sessionStorage.setItem("stores", "[]");
};

export const setAuth = (auth) => {
  sessionStorage.setItem("id", auth.id);
  sessionStorage.setItem("name", auth.name);
  sessionStorage.setItem("domainJson", JSON.stringify(auth.domainJson));
  sessionStorage.setItem("rk", auth.rk);
  sessionStorage.setItem("pkm2", auth.pkm2);
  sessionStorage.setItem("pke2", auth.pke2);
  sessionStorage.setItem("stores", JSON.stringify(auth.stores));
};

export const setDataViewStyle = (dataView) => {
  sessionStorage.setItem("dataViewStyle", dataView);
};
export const getDataViewStyle = () => {
  let tmp = sessionStorage.getItem("dataViewStyle");
  if (tmp) tmp = tmp * 1;
  else {
    tmp = 100;
    setDataViewStyle(100);
  }
  return tmp;
};

export const setOnChangeView = (view) => {
  sessionStorage.setItem("onChangeView", view);
};
export const getOnChangeView = () => {
  return sessionStorage.getItem("onChangeView");
};

export const isO = (rk) => {
  //Onwer check
  return sessionStorage.getItem("rk") === rk;
};

export const hasRole = (mdoUid, domains) => {
  const has =
    domains?.filter(
      (domain) => domain.uid === mdoUid && right(domain.rights, READ_IDX)
    ) ?? [];
  return has.length > 0;
};
