import React, { useEffect, useState } from "react";

import { BANK_TYPE_OPTIONS } from "core";

function BankCodeSelectPopup(props) {
  //===========================================================================
  const { formData, setFormData, onRequestClose = () => {} } = props;
  const [selectData, setSelectData] = useState({});
  //===========================================================================
  const handleChangeSelect = (item) => {
    setSelectData(item);
  };
  const handleSubmitSelect = () => {
    setFormData({ ...formData, employeeBankCode: selectData.value });
    onRequestClose();
  };
  //===========================================================================
  useEffect(() => {
    if (formData.employeeBankCode)
      setSelectData(
        BANK_TYPE_OPTIONS.find(
          (item) => item.value === formData.employeeBankCode
        )
      );
  }, [formData.employeeBankCode]);

  //===========================================================================
  return (
    <>
      <div className="pop_layer pop_bt_layer">
        <div className="pop_bt_cont">
          <div className="popup_header">
            <span>은행선택</span>
            <span onClick={onRequestClose} className="closed_btn"></span>
          </div>
          <div className="cont">
            <ul className="account-add-box">
              <li className="jp_logo_att">
                <ul className="login_lt mb16px">
                  <li className="bank-popup-y-scroll">
                    <div className="three_chk_packing ">
                      {BANK_TYPE_OPTIONS.map((item, idx) => {
                        return (
                          <div
                            key={idx}
                            className="txt-select-box"
                            onClick={() => handleChangeSelect(item)}
                          >
                            <span
                              className={
                                "select_chk bank-font-14x" +
                                (selectData.value === item.value
                                  ? " sel_on"
                                  : "")
                              }
                            >
                              {item.label}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </li>
                </ul>

                <div
                  className={
                    "acc-btn platform-btn" + (selectData ? " acc-on" : "")
                  }
                  onClick={handleSubmitSelect}
                >
                  적용
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default BankCodeSelectPopup;
