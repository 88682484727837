import React from "react";

import { HeaderRightProfile } from "components";

function Header(props) {
  //===========================================================================
  const { $app, title = "" } = props;
  //===========================================================================
  //===========================================================================
  return (
    <>
      <div className="hd_layer">
        <i className="logo"></i>
        <span className="title" id="hdTitle">
          {title}
        </span>
        <div className="right">
          <HeaderRightProfile />
        </div>
      </div>
    </>
  );
}

export default Header;
