import { security } from "core";
import { HOSTNAME } from "core/const";

const ProgressHandler = (e) => {
  // loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`;
  //   let percent = Math.floor((e.loaded / e.total) * 10000) / 100;
  //   setPercent(percent);
};

const SuccessHandler = (e) => {
  //   let imgs = JSON.parse(e.target.response)?.data?.images || [];
  //   setImages(remakeImages(imgs));
  //   uploadRef.current.value = "";
  //   setMsg("업로드할 파일을 선택해주세요.");
  //   setPercent(0);
};
const ErrorHandler = () => {
  // statusRef.current.innerHTML = "upload failed!!";
};
const AbortHandler = () => {
  // statusRef.current.innerHTML = "upload aborted!!";
};

/**
 *
 *
 * @param {*} url
 * @param {*} params
 * @param {*} files
 * @returns
 */
export const uploadFiles = (url, params, files, sfn = () => {}) => {
  if (files.length === 0) return;
  var xhr = new XMLHttpRequest();
  xhr.upload.addEventListener("progress", ProgressHandler, false);
  xhr.addEventListener("load", sfn, false);
  xhr.addEventListener("error", ErrorHandler, false);
  xhr.addEventListener("abort", AbortHandler, false);
  xhr.open("POST", HOSTNAME + url);
  xhr.send(security.encU(params, files));
};
