import React, { Children } from "react";
import Modal from "react-modal";

const customStyles = {
  overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "999" },
  content: {
    inset: "20px",
    border: "0px",
    background: "",
    padding: "0px",
    margin: "60px 0px 0px 0px",
  },
  //   content: {
  //     top: "50%",
  //     left: "50%",
  //     right: "auto",
  //     bottom: "auto",
  //     marginRight: "-50%",
  //     transform: "translate(-50%, -50%)",
  //   },
};

// Modal.setAppElement("#yourAppElement");
Modal.setAppElement("#root");

function ModalW({
  isOpen = false,
  onAfterOpen = () => {},
  onRequestClose = () => {},
  contentLabel = "",
  children,
}) {
  //===========================================================================
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("app")}
    >
      {children}
    </Modal>
  );
}

export default ModalW;
