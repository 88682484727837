import { atom } from "recoil";
import { post, put, get } from "core";

const defaultValue = [];

export const Caller = {
  //===========================================================================
  dftVal: defaultValue,
  state: atom({
    key: "storeLaborContractState",
    default: defaultValue,
  }),
  //===========================================================================
  add: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/store/employee/contracts", tmp);
      },
      sfn: ({ data }) => sfn(data.storeEmployeeContracts, data),
      ffn: ({ status }) => ffn(status),
    });
  },
  update: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await put("/api/v1/store/employee/contracts", tmp);
      },
      sfn: ({ data }) => sfn(data.storeEmployeeContracts, data),
      ffn: ({ status }) => ffn(status),
    });
  },

  // 한사람의 계약서 : tecUid
  one: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await get("/api/v1/store/employee/contracts/one", tmp);
      },
      sfn: ({ data }) => {
        let tmp = data.storeEmployeeContract;
        tmp.weeklyWorkDays = JSON.parse(tmp.weeklyWorkDays);
        tmp.socialInsurance = JSON.parse(tmp.socialInsurance);
        if (tmp.extraPayJson) {
          tmp.extraPay = JSON.parse(tmp.extraPayJson);
          delete tmp.extraPayJson;
        }
        // if (item.payday < 32) item.payday = String(item.payday);
        sfn(tmp, data);
      },
      ffn: ({ status }) => ffn(status),
    });
  },
  // 서명완료 :  uid, employeeJuminNum, employeeEmail, employeeAddr1, employeeAddr2, employeeSign
  completed: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/store/employee/contracts/completed", tmp);
      },
      sfn: ({ data }) => {
        sfn(tmp, data);
      },
      ffn: ({ status }) => ffn(status),
    });
  },
  // 계약 종료 : uid, workEndDate
  end: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await put("/api/v1/store/employee/contracts/workEndDate", tmp);
      },
      sfn: ({ data }) => sfn(data.storeEmployeeContracts, data),
      ffn: ({ status }) => ffn(status),
    });
  },

  //===========================================================================
  list: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, { qs: "", page: 0, size: 1000 }, params);
    $app.req({
      fn: async () => {
        return await get("/api/v1/store/employee/contracts", tmp);
      },
      sfn: ({ data }) => {
        data.storeEmployeeContracts?.forEach((item) => {
          item.weeklyWorkDays = JSON.parse(item.weeklyWorkDays);
          item.socialInsurance = JSON.parse(item.socialInsurance);
          if (item.extraPayJson) {
            item.extraPay = JSON.parse(item.extraPayJson);
            delete item.extraPayJson;
          }
          // if (item.payday < 32) item.payday = String(item.payday);
        });

        sfn(data.storeEmployeeContracts, data);
      },
      ffn: ({ status }) => ffn(status),
    });
  },
};
