import React, { useRef, useEffect, useState } from "react";
import SignaturePad from "signature_pad";

function ContractSignaturePad(props) {
  //===========================================================================
  const { onRequestSign = () => {}, onRequestClose = () => {} } = props;
  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const [btnValid, setbtnValid] = useState("");
  //===========================================================================
  useEffect(() => {
    const canvas = canvasRef.current;
    signaturePadRef.current = new SignaturePad(canvas, {
      minWidth: 1,
      maxWidth: 2.5,
      throttle: 16,
      minDistance: 5,
    });
    setbtnValid(signaturePadRef.current.toDataURL());
  }, []);

  const clear = () => {
    signaturePadRef.current.clear();
  };

  const save = () => {
    const signature = signaturePadRef.current.toDataURL();
    onRequestSign(signature);
    onRequestClose(false);
  };

  //===========================================================================
  return (
    <>
      <div className="pop_layer pop_bt_layer">
        <div className="pop_bt_cont bg-wht">
          <div className="popup_header">
            <span className="title">계약 서명하기</span>
            <span className="closed_btn" onClick={onRequestClose}></span>
          </div>
          <div className="popup_mid signpopup_mid">
            <p className="guide">아래 서명 패드에 서명을 남겨주세요</p>
            <div className="sign_ipt">
              <canvas
                ref={canvasRef}
                width={300}
                height={170}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="popup_ft">
            <div className="two_btn_packing">
              <button
                className="sm_wide_btn bt_btn dateinc_btn bk_btn"
                onClick={clear}
              >
                초기화 하기
              </button>
              <button
                className={"sm_wide_btn bt_btn" + (btnValid ? " on" : "")}
                onClick={save}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractSignaturePad;
