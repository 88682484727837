import axios from "axios";
import queryString from "query-string";
import { default as security } from "./security";
import { HOSTNAME } from "core/const";

const config = () => ({
  headers: {
    "x-auth-token": sessionStorage.getItem("rk") || "guest",
    "Content-Type": "application/json;charset=UTF-8",
  },
});
let cancelToken;
export const get = (url, data) => {
  if (typeof cancelToken != typeof undefined) {
    // cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();

  return axios.get(
    `${HOSTNAME}${url}?${queryString.stringify(security.encG(data))}`,
    Object.assign({}, { cancelToken: cancelToken.token }, config())
  );
};

export const post = (url, data) =>
  axios.post(HOSTNAME + url, security.encG(data), config());

export const put = (url, data) =>
  axios.put(HOSTNAME + url, security.encG(data), config());

export const patch = (url, data) =>
  axios.patch(HOSTNAME + url, security.encG(data), config());

export const del = (url, data) =>
  axios.delete(HOSTNAME + url, {
    headers: config().headers,
    data: security.encG(data),
  });

export const upload = (url, data, files) => {
  console.log("---> data", JSON.stringify(data));
  console.log("---> files", files);
  return axios.post(HOSTNAME + url, security.encU(data, files), {
    headers: {
      "x-auth-token": sessionStorage.getItem("rk") || "guest",
      "Content-Type": "multipart/form-data;charset=UTF-8",
      "X-Requested-With": "XMLHttpRequest",
    },
  });
};
