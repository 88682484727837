// export const HOSTNAME = "http://bo.datos.co.kr";
// export const HOSTNAME = "http://" + window.location.hostname + ":8080";
export const HOSTNAME = "https://www.salida.biz";

export const MDO_ZERO = "200000000";
export const READ_IDX = "1";
export const WRITE_IDX = "2";
export const ADMIN_IDX = "3";

export const OFFICIAL = "10";
export const INFORMALITY = "20";

export const MESSAGE_OPTIONS = [
  { label: "한글", value: "ko" },
  { label: "영어", value: "en" },
];
