import React, { useState } from "react";

import { Modal } from "components";
import { ContractSignaturePad } from ".";

function ContractSign(props) {
  //===========================================================================
  const { formData, setFormData } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //===========================================================================
  const handleChangeModal = (open) => {
    setModalIsOpen(open);
  };
  const viewOwnerSign = () => {
    if (formData.ownerSign)
      return <img src={formData.ownerSign} alt="sign" className="sign-size" />;
  };
  const viewEmployeeSign = () => {
    if (formData.employeeSign)
      return (
        <img src={formData.employeeSign} alt="sign" className="sign-size" />
      );
  };
  const getEmployeeSign = (sign) => {
    setFormData({ ...formData, employeeSign: sign });
  };
  //===========================================================================

  return (
    <div className="ctr_sign">
      <div className="sign_item">
        <div className="info">
          <span className="title">사업주</span>

          <ul className="info_cont lt_info">
            <li>
              <span className="title">사업체명</span>
              <p>
                {formData.storeName} {formData.storeChainName}
              </p>
            </li>
            <li>
              <span className="title">주소</span>
              <p>
                {formData.storeAddr1} {formData.storeAddr2}
              </p>
            </li>
            <li>
              <span className="title">연락처</span>
              <p>
                {formData.storeOwnerPhone?.replace(
                  /^(\d{2,3})(\d{3,4})(\d{4})$/,
                  `$1-$2-$3`
                )}
              </p>
            </li>
            <li className="sign_box">
              <span className="title">대표자</span>
              <div className="sign">
                <span>{formData.storeOwnerName}</span>
                <div className="sign_index">
                  <span> (인)</span>
                  <div className="contract-sign">{viewOwnerSign()}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="sign_item">
        <div className="info">
          <span className="title">근로자</span>

          <ul className="info_cont lt_info">
            <li>
              <span className="title">주소</span>
              <p>
                {formData.employeeAddr1} {formData.employeeAddr2}
              </p>
            </li>
            <li>
              <span className="title">연락처</span>
              <p>
                {formData.employeePhone?.replace(
                  /^(\d{2,3})(\d{3,4})(\d{4})$/,
                  `$1-$2-$3`
                )}
              </p>
            </li>
            <li className="sign_box">
              <span className="title">성명</span>
              <div className="sign">
                <span>{formData.employeeName}</span>
                <div className="sign_index">
                  <span> (인)</span>
                  <div className="contract-sign">{viewEmployeeSign()}</div>
                </div>
              </div>
            </li>
            <li>
              <div
                onClick={() => handleChangeModal(true)}
                className={
                  "select_chk " + (formData.employeeSign ? "" : " sel_on")
                }
              >
                서명하기
              </div>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => handleChangeModal(false)}
              >
                <ContractSignaturePad
                  onRequestSign={getEmployeeSign}
                  onRequestClose={() => handleChangeModal(false)}
                />
              </Modal>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ContractSign;
