import React, { useState, useEffect } from "react";

import { uploadFiles, HOSTNAME, setOnChangeView } from "core";
import { Modal } from "components";
import { ImageGallery } from "domain";
import { ContractAddDocForm } from "domain";

const TYPE = { FILE_ADD: "fileAdd", FILE_VIEW: "fileView" };

function ContractAddDocContentsComp(props) {
  //===========================================================================
  const {
    $app,
    tecUid,
    docList,
    isPopup = false,
    isInDocumentBox = false,
    onChangeClose = () => {},
  } = props;
  const [admissionDoc, setAdmissionDoc] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [activeBtn, setActiveBtn] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState({
    fileAdd: false,
    fileView: false,
  });
  //===========================================================================
  const handleChangeModal = (type, open, item) => {
    setModalIsOpen({ ...modalIsOpen, [type]: open });
    setSelectedDoc(item);
  };

  const handleSubmitClick = () => {
    if (activeBtn) {
      admissionDoc.forEach((ad) => {
        if (ad.files && ad.files.length > 0) {
          const files = ad.files.map((file) => file.file);
          const excludedUids = ad.files
            .map((file) => (typeof file === "string" ? file : ""))
            .join(",");
          const params = { tecUid, tadUid: ad.uid, excludedUids };
          uploadFiles(
            "/api/v1/store/employee/admission/documents",
            params,
            files,
            () => {
              setOnChangeView("reloadAddDoc");
            }
          );
        }
      });
    }
    if (isPopup) onChangeClose();
    setActiveBtn("");
  };

  const handleSubmitFiles = (docFiles) => {
    if (docFiles.length > 0) {
      let tmp = [...admissionDoc];
      tmp = tmp.map((item) => {
        if (item.uid === selectedDoc.uid) {
          return { ...item, files: docFiles };
        } else {
          return item;
        }
      });
      setActiveBtn("on");
      setAdmissionDoc(tmp);
    }
  };
  //===========================================================================
  useEffect(() => {
    if (docList) setAdmissionDoc(docList);
  }, [docList]);
  //===========================================================================
  return (
    <>
      <div className={isPopup ? "popup_mid pop-add-doc-100vh" : "mid"}>
        <ul className="chk_doc_list">
          {Array.isArray(admissionDoc) &&
            admissionDoc?.map((item, idx) => (
              <li key={idx}>
                <div className="upload-doc-list">
                  <span className="item img-box">
                    <ul
                      className="overlap_layer"
                      onClick={() =>
                        handleChangeModal(TYPE.FILE_VIEW, true, item)
                      }
                    >
                      {item.files &&
                        item.files.length > 0 &&
                        item.files.map((img, jdx) => {
                          if (img.imgSrc) {
                            return (
                              <li key={jdx}>
                                <span className="doc-thumb">
                                  <img src={img.imgSrc} alt="preview" />
                                </span>
                              </li>
                            );
                          } else if (typeof img === "string") {
                            return (
                              <li key={jdx}>
                                <span className="doc-thumb">
                                  {img ? (
                                    <img
                                      src={`${HOSTNAME}/api/v1/images/${img}/img.png`}
                                      alt="preview"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </li>
                            );
                          }
                        })}
                      {item.files.length === 0 && (
                        <li key={idx}>
                          <span className="doc-thumb"></span>
                        </li>
                      )}
                    </ul>
                  </span>
                  <span
                    className="item"
                    onClick={() => handleChangeModal(TYPE.FILE_ADD, true, item)}
                  >
                    <span
                      className={isInDocumentBox ? "doc-store-name" : " blind"}
                    >
                      {item.storeName} {item.storeChainName}
                    </span>
                    {item.name}
                  </span>
                </div>
              </li>
            ))}
        </ul>
        <Modal
          isOpen={modalIsOpen.fileAdd}
          onRequestClose={() => handleChangeModal(TYPE.FILE_ADD, false)}
        >
          <ContractAddDocForm
            selectedDoc={selectedDoc}
            onRequestClose={() => handleChangeModal(TYPE.FILE_ADD, false)}
            onSubmitFiles={handleSubmitFiles}
          />
        </Modal>
        <Modal
          isOpen={modalIsOpen.fileView}
          onRequestClose={() => handleChangeModal(TYPE.FILE_VIEW, false)}
        >
          <ImageGallery
            docImages={selectedDoc}
            onRequestClose={() => handleChangeModal(TYPE.FILE_VIEW, false)}
          />
        </Modal>
      </div>
      <div className={isPopup ? "popup_ft" : "mid mb16px"}>
        <div className="btn_packing">
          <button
            className={"sm_wide_btn bt_btn" + (activeBtn ? " on" : "")}
            onClick={handleSubmitClick}
          >
            {isPopup ? "완료" : "저장"}
          </button>
        </div>
      </div>
    </>
  );
}

export default ContractAddDocContentsComp;
