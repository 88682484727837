import React from "react";
import { Link, useLocation } from "react-router-dom";

function NotFoundPage() {
  const location = useLocation();
  return (
    <div style={{ textAlign: "center" }}>
      <Link to="/personal">
        <img src="assets/img/page_not_found.svg" alt="" />
        홈으로
      </Link>
    </div>
  );
}

export default NotFoundPage;
