import React, { useState, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";

import { makeMonth, getPeriodWithYear, today, selectedStore } from "core";
import { StoreLaborEmployeeAttendancesHelper, AuthorityHelper } from "domain";

const defaultYear = new Date().getFullYear();
const defaultMonth = new Date().getMonth() + 1;

function LaborAttendanceCalendarWidget(props) {
  //===========================================================================
  const initMonth = () => {
    return makeMonth(defaultYear, defaultMonth);
  };
  const initFilters = () => {
    const [startDate, endDate] = getPeriodWithYear(defaultYear, defaultMonth);
    const filters = {
      year: defaultYear % 2000, // 화면용
      month: defaultMonth, // 화면용
      startDate,
      endDate,
    };
    return filters;
  };
  //===========================================================================
  const {
    $app,
    mstUid,
    mseUid,
    onDateClick = () => {},
    attendanceRefresh,
    onChangeView = () => {},
    viewOptions = {},
  } = props;
  const logined = useRecoilValue(AuthorityHelper.state);
  const store = selectedStore(logined);
  const [month, setMonth] = useState(initMonth());
  const [filters, setFilters] = useState(initFilters());
  const [isSelect, setIsSelect] = useState(null);
  //===========================================================================
  const remakeData = (resData) => {
    const tmpData = [...resData, {}];
    let tmpArr = [];
    let tmpDate = resData[0]?.attendanceDate;
    let tmpGroup = [];
    let tmpMins = 0;

    tmpData?.forEach((item) => {
      if (tmpDate !== item.attendanceDate) {
        tmpArr.push({
          ymd: tmpDate,
          dailyMins: Math.floor(tmpMins / 60),
          dailyCount: tmpGroup.length,
        });
        tmpDate = item.attendanceDate;
        tmpMins = 0;
        tmpGroup = [];
      }
      tmpMins =
        tmpMins +
        (item.start === 100 && item.end === 100 ? item.workMinutes : 0);
      tmpGroup.push(item);
    });

    const mergedArray = month.map((item1) => {
      const matchedItem = tmpArr.find((item2) => item1.ymd === item2.ymd);
      if (matchedItem) {
        return { ...item1, ...matchedItem };
      }
      return item1;
    });

    setMonth(mergedArray);
  };

  const loadData = () => {
    StoreLaborEmployeeAttendancesHelper.history(
      $app,
      { ...filters, mstUid },
      (resData) => {
        if (resData[0]) {
          remakeData(resData.filter((item) => item.mseUid === mseUid));
        }
      }
    );
  };
  //===========================================================================
  useEffect(() => {
    if (store.uid)
      setFilters((prevState) => ({ ...prevState, mstUid: store.uid }));
  }, [store.uid]);
  useEffect(() => {
    loadData();
  }, [filters, attendanceRefresh]);
  //===========================================================================
  const checkThisMonth = (date) => {
    const pattern = /^\d{1,2}\/\d{1,2}$/;
    if (pattern.test(date)) return " greyDate";
    else return "";
  };

  const handleClickPrev = () => {
    let tmpY = filters.year * 1;
    const tmpM = filters.month * 1;
    let prevNum = tmpM - 1;
    if (prevNum <= 0) {
      tmpY = tmpY - 1;
      prevNum = 12;
    }
    const [startDate, endDate] = getPeriodWithYear(tmpY + 2000, prevNum);
    setMonth(makeMonth(tmpY + 2000, prevNum));
    setFilters((prevState) => ({
      ...prevState,
      year: startDate.substring(2, 4),
      month: prevNum,
      startDate,
      endDate,
    }));
  };
  const handleClickNext = () => {
    let tmpY = filters.year * 1;
    const tmpNum = filters.month * 1;
    let nextNum = tmpNum + 1;
    if (nextNum > 12) {
      tmpY = tmpY + 1;
      nextNum = 1;
    }
    const [startDate, endDate] = getPeriodWithYear(tmpY + 2000, nextNum);
    setMonth(makeMonth(tmpY, nextNum));
    setFilters((prevState) => ({
      ...prevState,
      year: startDate.substring(2, 4),
      month: nextNum,
      startDate,
      endDate,
    }));
  };
  const handleDateClick = (ymd, idx) => {
    if (isSelect === null || isSelect !== idx) setIsSelect(idx);
    else setIsSelect(null);
    onDateClick(ymd);
  };
  // const handleWidgetClick = () => {
  //   onChangeView({
  //     view: viewOptions.ATTENDANCE_HISTORY,
  //     prev: viewOptions.MAIN,
  //   });
  // };
  //===========================================================================
  const MonthComp = useMemo(() => {
    return (
      <ul className="content_date">
        {month.map((day, idx) => (
          <li
            key={idx}
            className={"item to day" + (isSelect === idx ? " selected-on" : "")}
          >
            <Link
              onClick={() => handleDateClick(day.ymd, idx)}
              className="m-v_btn"
            ></Link>

            <p className="date">
              <span
                className={
                  (day.ymd === today() ? " today-on lb-bg-color" : "") +
                  checkThisMonth(day.d)
                }
              >
                {day.d}
              </span>
            </p>
            <span className="n_txt attend_txt">
              {day.dailyCount ? (
                <>
                  <span className={day.dailyMins === 0 ? " blind" : ""}>
                    {"("}
                    {day.dailyMins}
                    {")"}
                  </span>
                </>
              ) : (
                <></>
              )}
            </span>
          </li>
        ))}
      </ul>
    );
  }, [handleDateClick, isSelect]);
  //===========================================================================
  return (
    <div className="callendar_packing">
      <div className="header lb-bg-color">
        <div className="month">
          <button className="left month_btn" onClick={handleClickPrev}>
            {filters.month === 1
              ? filters.year - 1 + "년" + 12
              : filters.month - 1}
            월
          </button>
          <span className="month_txt">
            {filters.year}년 {filters.month}월
          </span>
          <button className="right month_btn" onClick={handleClickNext}>
            {filters.month === 12
              ? filters.year * 1 + 1 + "년" + 1
              : filters.month + 1}
            월
          </button>
        </div>
        <div className="date">
          <p className="item">일</p>
          <p className="item">월</p>
          <p className="item">화</p>
          <p className="item">수</p>
          <p className="item">목</p>
          <p className="item">금</p>
          <p className="item">토</p>
        </div>
      </div>
      {MonthComp}
      <div className="callendar-price-unit lb-bg-color">
        <span>단위: 인원(시간) &nbsp;</span>
      </div>
    </div>
  );
}

export default LaborAttendanceCalendarWidget;
