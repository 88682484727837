import { clearAuth } from "core";

export const resChk = ({ res, success, failed, navigate }) => {
  if (res.data.status.code === 2000 && success) success(res.data.data);
  else {
    if (res.data.status.code === 5004) {
      clearAuth();
      navigate("/login");
    } else {
      // alert(res.data.status.msg);
    }
    if (failed) failed();
  }
  return res.data;
};
