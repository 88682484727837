import React from "react";
import { useNavigate } from "react-router-dom";

import { ContractAddDocContentsComp } from "domain";

function ContractAddDocComp(props) {
  //===========================================================================
  const { $app, tecUid, docList, onRequestClose = () => {} } = props;
  const navigate = useNavigate();
  //===========================================================================

  const handleChangeClose = () => {
    onRequestClose();
    navigate("/labor");
  };
  //===========================================================================
  return (
    <>
      <div className="pop_layer pop_bt_layer ">
        <div className="bg-wht">
          <div className="popup_header">
            <span className="title"> 추가서류 등록하기 </span>
            <span className="closed_btn" onClick={handleChangeClose}></span>
          </div>

          <ContractAddDocContentsComp
            $app={$app}
            tecUid={tecUid}
            docList={docList}
            isPopup={true}
            onChangeClose={handleChangeClose}
          />
        </div>
      </div>
    </>
  );
}

export default ContractAddDocComp;
