import React from "react";
import { useNavigate, Link } from "react-router-dom";

import { HeaderRightProfile } from "./right";

function HeaderArrow(props) {
  //===========================================================================
  const { $app, onBack, title = "" } = props;
  const navigate = useNavigate();
  //===========================================================================
  const back = () => {
    if (typeof onBack === "function") onBack();
    else navigate(-1);
  };
  //===========================================================================
  return (
    <>
      <div className="hd_layer">
        <Link onClick={() => back()} className="left_btn">
          <i className="arrow"></i>
        </Link>
        <span className="title" id="hdTitle">
          {title}
        </span>
        <div className="right">
          <HeaderRightProfile />
        </div>
      </div>
    </>
  );
}

export default HeaderArrow;
