import React, { useState, useMemo, useEffect, useCallback } from "react";

import { addMonths, today, getPeriod, scrollLock, scrollUnlock } from "core";
import { DatePicker, RadioGroup, Radio } from "components";

function DailyFilter(props) {
  //===========================================================================
  const initFilters = () => {
    return {
      mstUids: data.mstUids,
      startDate: data.startDate,
      endDate: data.endDate,
      period: data.period || "100",
      item: data.item,
      open: false,
      blind: true,
    };
  };
  const {
    lpKey = "000",
    data = {},
    onFilters = () => {},
    labelOptions,
    filterColor = "",
    // view = 0,
  } = props;
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(initFilters());
  //===========================================================================
  useEffect(() => {
    setFilters((prevState) => ({ ...prevState, mstUids: data.mstUids }));
  }, [data]);
  useEffect(() => {
    open ? scrollLock() : scrollUnlock();
  }, [open]);
  // useEffect(() => {
  //   setOpen(false);
  // }, [view]);
  //===========================================================================
  const handleChangeDate = ({ name, value }) => {
    setFilters({ ...filters, [name]: value });
  };
  const handleChangeItem = useCallback(
    (value) =>
      setFilters((prevState) => ({
        ...prevState,
        item: labelOptions.find((option) => option.id === value),
        open: false,
      })),
    [labelOptions]
  );
  const handleChangePeriod = useCallback((value) => {
    let changedData = {};
    if (value === "100") {
      changedData = { startDate: addMonths(today(), -1), endDate: today() };
    } else if (value === "200") {
      changedData = { startDate: addMonths(today(), -3), endDate: today() };
    }
    setFilters((prevState) => ({
      ...prevState,
      blind: value === "300" ? false : true,
      period: value,
      ...changedData,
      open: false,
    }));
  }, []);
  const handleToggleDetails = (e) => {
    setFilters((prevState) => ({
      ...prevState,
      blind: true,
      open: e.target.open,
    }));
  };
  const handleClickCloseOptions = (period) => {
    const [startDate, endDate] = getPeriod(period);
    setFilters((prevState) => ({
      ...prevState,
      blind: true,
      period,
      startDate,
      endDate,
      open: !prevState.open,
    }));
  };
  const handleClickApply = useCallback(() => {
    const { item, mstUids, startDate, endDate, period } = filters;
    onFilters({ item, mstUids, startDate, endDate, period });
    setOpen(false);
  }, [filters, onFilters]);
  const handleCancel = () => {
    setFilters(initFilters());
    setOpen(false);
  };
  //===========================================================================
  const Period11Comp = useMemo(() => {
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    return (
      <div>
        <RadioGroup
          className="sub_item col8 col-4"
          key={lpKey}
          name="period"
          value={filters.period}
          onChange={handleChangePeriod}
        >
          <Radio value="100" className="bd_chk">
            1개월
          </Radio>
          <Radio value="200" className="bd_chk">
            3개월
          </Radio>
          <details
            className="custom-select bd-sl"
            open={filters.open}
            onToggle={handleToggleDetails}
          >
            <summary
              className={
                filters.period > 0 && filters.period < 13
                  ? "radios checked"
                  : "radios"
              }
            >
              {filters.period > 0 && filters.period < 13
                ? filters.period + "월"
                : "월별"}
            </summary>
            <ul className="list select_lt">
              {months.map((month, idx) => (
                <li key={idx}>
                  <label
                    name="select_lb"
                    htmlFor={"item" + month}
                    onClick={() => handleClickCloseOptions(month)}
                  >
                    {month}월<span></span>
                  </label>
                </li>
              ))}
            </ul>
          </details>
          <Radio value="300" className="bd_chk">
            직접선택
          </Radio>
        </RadioGroup>
        <div className={"date_picker" + (filters.blind ? " blind" : "")}>
          <div className="date_box">
            <DatePicker
              name={"startDate"}
              onChange={({ name, value }) => handleChangeDate({ name, value })}
              value={filters.startDate}
              placeholder={"시작일"}
              pickerClassName={"datePicker-padding"}
              pickerHeight={200}
            />
          </div>
          <span className="wave v-middle">-</span>
          <div className="date_box">
            <DatePicker
              name={"endDate"}
              onChange={({ name, value }) => handleChangeDate({ name, value })}
              value={filters.endDate}
              placeholder={"종료일"}
              pickerClassName={"datePicker-padding"}
              pickerHeight={200}
            />
          </div>
        </div>
      </div>
    );
  }, [
    filters.open,
    filters.period,
    filters.blind,
    filters.startDate,
    filters.endDate,
    lpKey,
    handleChangePeriod,
  ]);
  //===========================================================================
  const ItemsComp = useMemo(() => {
    return (
      <div>
        <RadioGroup
          className={
            "sub_item col8" + (labelOptions.length > 10 ? " col-3" : " col-2")
          }
          key={lpKey}
          name="item"
          value={filters.item.id}
          onChange={handleChangeItem}
        >
          {labelOptions?.map((option, idx) => (
            <Radio value={option.id} className="bd_chk" key={idx}>
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
      </div>
    );
  }, [filters.item, labelOptions, lpKey, handleChangeItem]);
  //===========================================================================
  const DailyFilterComp = useMemo(() => {
    return (
      <div>
        <div className="filter_packing">
          <button onClick={() => setOpen(true)} className="trans_back">
            <div className={"filter_box " + filterColor}>
              <p className="title">
                기간+항목 : {data.startDate + " ~ " + data.endDate}
              </p>
            </div>
          </button>
        </div>
        <div
          className={
            "pop_docker dim modal_window pop-filter_docker" +
            (open ? " on" : "")
          }
          id="filter"
        >
          <button
            onClick={() => setOpen(false)}
            className="out_btn trans_back"
          ></button>
          <div className="pop_layer pop_filter_layer">
            <div className="filter_cont bg-wht">
              <div className="popup_header">
                <span>필터</span>
                <button
                  className="closed_btn trans_back"
                  onClick={handleCancel}
                ></button>
              </div>
              <ul className="filter_lt">
                <li className="item">
                  <p className="title">기간</p>
                  {Period11Comp}
                </li>
                {labelOptions.length === 0 ? (
                  <li className="item txt-center">
                    <img
                      src="../img/c_soon_2.svg"
                      alt="ad"
                      className="coming-soon"
                    />
                  </li>
                ) : (
                  <li className="item">
                    <p className="title">항목</p>
                    {ItemsComp}
                  </li>
                )}
                <li className="item btn_item">
                  <button
                    onClick={handleClickApply}
                    className="sm_wide_btn bt_btn on dateinc_btn trans_back"
                  >
                    적용하기
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line
  }, [data, open, Period11Comp, ItemsComp, labelOptions, handleClickApply]);

  //===========================================================================
  return <>{DailyFilterComp}</>;
}

export default DailyFilter;
