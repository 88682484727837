import React from "react";

function NoData(props) {
  //===========================================================================
  const {
    message = "요청하신 데이터가 없습니다.",
    subMessage = "다시 한번 확인해 주세요.",
    displayType = 100,
  } = props;
  //===========================================================================
  return (
    <>
      {displayType === 100 ? (
        <div className="alert_packing">
          <i className="nodata_icon"></i>
          <p className="title">{message}</p>
        </div>
      ) : null}

      {/* 200은 하프사이즈 */}
      {displayType === 200 ? (
        <div className="noData-half-box">
          <div className="nodata-half-img">&nbsp;</div>
          <div className="noData-half-msg">{message}</div>
        </div>
      ) : null}

      {displayType === 300 ? (
        <div className="mid">
          <div className="cont">
            <div className="noData-msg">{message}</div>
          </div>
        </div>
      ) : null}

      {displayType === 400 ? (
        <div className="alert_packing">
          <i className="nodata_icon"></i>
          <p className="title">{message}</p>
          <span className="sub_txt txt-center">{subMessage}</span>
        </div>
      ) : null}
    </>
  );
}

export default NoData;
