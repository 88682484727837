import React, { useEffect, useState } from "react";

import { userBgCode, minToHour } from "core";

function DailyAttendanceSum(props) {
  //===========================================================================
  const { data = [] } = props;
  const [allTotWorkMins, setAllTotWorkMins] = useState(0);
  const [allTotPayMins, setAllTotPayMins] = useState(0);
  //===========================================================================
  useEffect(() => {
    setAllTotWorkMins(
      data.reduce((total, item) => total + item.totWorkMins, 0)
    );
    setAllTotPayMins(data.reduce((total, item) => total + item.totPayMins, 0));
  }, [data]);
  //===========================================================================
  return (
    <>
      <ul className="ma_summary summary">
        <li className="graph_item item">
          <div className="txt">
            <p className="title">
              <span className="">{data.length} </span> 명 총 근로시간
            </p>
            <span className="hour">
              {" "}
              {allTotWorkMins > 0 ? minToHour(allTotWorkMins) : ""}{" "}
              {allTotPayMins - allTotWorkMins > 0
                ? "(" + "+" + minToHour(allTotPayMins - allTotWorkMins) + ")"
                : ""}
            </span>
          </div>
          <div className="bar-graph" style={{ flexDirection: "row" }}>
            {data.map((item, idx) => (
              <i
                key={idx}
                className={userBgCode(item.employeeName)}
                style={{
                  flex:
                    isFinite(item.totWorkMins) &&
                    isFinite(allTotWorkMins) &&
                    allTotWorkMins !== 0
                      ? item.totWorkMins / allTotWorkMins
                      : 1,
                }}
              ></i>
            ))}
          </div>
        </li>

        {data.map((item, idx) => (
          <li className="item" key={idx}>
            <div className="txt">
              <p className="title user-name-size">
                <span
                  className={"user-bg-dot " + userBgCode(item.employeeName)}
                ></span>
                {item.employeeName}
                {item.workRespon}
              </p>
              <span className="hour">
                {item.totWorkMins > 0 ? minToHour(item.totWorkMins) : ""}
                {item.totPayMins - item.totWorkMins > 0
                  ? "(" +
                    "+" +
                    minToHour(item.totPayMins - item.totWorkMins) +
                    ")"
                  : ""}
              </span>
            </div>
          </li>
        ))}

        {/* <li className="sum item dot-item">
          <div className="txt">
            <p className="title">총계</p>
             <span className="price">{comma(dailyPaySumAmt)}</span> 
          </div>
        </li> */}
      </ul>
    </>
  );
}

export default DailyAttendanceSum;
