import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";

import { dateFormat, selectedStore, minToHour } from "core";
import { StoreLaborEmployeeAttendancesHelper, AuthorityHelper } from "domain";

function LaborDailyAttendanceSummaryWidget(props) {
  //===========================================================================
  const { $app, workDate, mstUid, mseUid, attendanceRefresh } = props;
  const logined = useRecoilValue(AuthorityHelper.state);
  const store = selectedStore(logined);
  const [filters, setFilters] = useState({});
  const [data, setData] = useState([]);
  const [dailyTot, setDailyTot] = useState({});
  //===========================================================================
  const calculatePayMins = (item) => {
    return (
      item.workNormal +
      item.workNormalOt * 1.5 +
      item.workNight * 1.5 +
      item.workNightOt * 2.0 +
      item.workHoliday * 1.5 +
      item.workHolidayOt * 2.0 +
      item.workHolidayNight * 2.0 +
      item.workHolidayNightOt * 2.5
    );
  };
  const remakeData = (resData) => {
    let tmpCount = 0;
    let tmpMinsTot = 0;
    let tmpPayMinsTot = 0;
    resData.forEach((item) => {
      const flag = item.start === 100 && item.end === 100;
      tmpCount += 1;
      tmpMinsTot += flag ? item.workMinutes : 0;
      tmpPayMinsTot += flag ? calculatePayMins(item) : 0;
    });
    setDailyTot({ count: tmpCount, mins: tmpMinsTot, payMins: tmpPayMinsTot });
  };
  const loadData = () => {
    StoreLaborEmployeeAttendancesHelper.history($app, filters, (resData) => {
      const tmp = resData.filter((item) => item.mseUid === mseUid);
      setData(tmp);
      remakeData(tmp);
    });
  };
  const sumEmployeePayMins = (item) => {
    const flag = item.start === 100 && item.end === 100;
    const mins = item.workMinutes;
    const payMins = calculatePayMins(item);
    return (
      <>
        {minToHour(flag ? mins : 0)}
        {"(" + "+" + minToHour(flag ? payMins - mins : 0) + ")"}
      </>
    );
  };
  //===========================================================================
  useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      mstUid: mstUid,
      startDate: workDate,
      endDate: workDate,
    }));
  }, [logined, workDate]);
  useEffect(() => {
    loadData();
  }, [filters, attendanceRefresh]);
  //===========================================================================
  return (
    <div className={"detail_packing" + (data?.length < 1 ? " blind" : "")}>
      <div className="dt_header">
        <ul className="dt_box">
          <li className="item">
            <div className="colmn">
              <span className="date">{dateFormat(workDate, "M.D")}</span>
              <div className="item_pk">
                <p className="sub_title"> 일 근로 시간</p>
                <p className="sub_info">
                  <span className="">
                    {store.name} {store.chainName}
                  </span>
                  {/* <span>총 {daily.count}명 근무</span> */}
                </p>
                <div className="hour">
                  {/* {dailyTot.count} <span className="small">명</span>, */}
                  {minToHour(dailyTot.mins)}{" "}
                  {"(" +
                    "+" +
                    minToHour(dailyTot.payMins - dailyTot.mins) +
                    ")"}
                  <span className="small">시간</span>
                </div>
              </div>
            </div>
          </li>

          <li className="jp_item">
            <div className="lt_mg">
              {/* <div className="row j-spb time_pk">
                <div className="item_pk">
                  <span className="sub_title"> 일 근로시간 </span>
                </div>
                <div className="time mb16px">
                  {minToHour(dailyTot.mins)}
                  {"(" +
                    "+" +
                    minToHour(dailyTot.payMins - dailyTot.mins) +
                    ")"}{" "}
                  &nbsp;
                </div>
              </div>
              <p className="summary-underline"></p> */}

              {data.map((item) => (
                <div className="row j-spb time_pk" key={item.uid}>
                  <div className="item_pk">
                    <span className="sub_title">{item.employeeName} </span>
                    <span className="sub_title grey_txt">
                      &nbsp;&nbsp;
                      {item.workRespon}
                    </span>
                  </div>
                  <div className="time">
                    {sumEmployeePayMins(item)}
                    &nbsp;
                  </div>
                </div>
              ))}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LaborDailyAttendanceSummaryWidget;
