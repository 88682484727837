import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { AuthorityHelper } from "domain";

const initData = {
  id: "",
  pw: "",
};
const initMsgs = [];

function Login(props) {
  //===========================================================================
  const { $app } = props;
  const [data, setData] = useState(initData);
  const [msgs, setMsgs] = useState(initMsgs);
  const setLogined = useSetRecoilState(AuthorityHelper.state);
  //===========================================================================
  const handleInputChange = (e) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };
  const handleLogin = () => {
    if (isInvalidData()) return;
    submitLogin(data);
  };
  //===========================================================================
  const isInvalidData = () => {
    let isInvalid = false;
    const { id, pw } = data;
    let tmpMsgs = [];
    if (id.length === 0) tmpMsgs.push("아이디를 입력해주세요");
    if (pw.length === 0) tmpMsgs.push("비밀번호를 입력해주세요");
    if (tmpMsgs.length > 0) isInvalid = true;
    setMsgs(tmpMsgs);
    return isInvalid;
  };
  const submitLogin = (params) => {
    AuthorityHelper.login($app, params, (resData) => {
      setLogined(resData);
      $app.clearHistory();
    });
  };
  //===========================================================================
  const MsgsComp = useMemo(() => {
    return msgs.map((msg, idx) => (
      <span key={idx} className="noti_txt">
        {msg}
      </span>
    ));
  }, [msgs]);
  //===========================================================================
  return (
    <>
      <div className="cont">
        <ul className="login_lt">
          <li>
            <input
              name="id"
              onChange={handleInputChange}
              className="comn_ip wide id_ip login_ip"
              type="text"
              placeholder="아이디를 입력해주세요"
            />
          </li>
          <li>
            <input
              name="pw"
              onChange={handleInputChange}
              className="comn_ip wide pw_ip login_ip"
              type="password"
              placeholder="비밀번호를 입력해주세요"
            />
          </li>
          <li className="error">{MsgsComp}</li>
        </ul>
        <div className="wide_btn_box">
          {/* <!--색상변경 : on 클래스로 제어--> */}
          <button onClick={handleLogin} className="sm_wide_btn bt_btn on">
            로그인
          </button>
        </div>
      </div>
    </>
  );
}

export default Login;
