import moment from "moment";
import { lpad } from "core";

export const dateFormat = (date, format) => {
  let tmp = moment(date, "y-MM-DD");
  return tmp.format(format);
};

export const dateFormatKW = (date) => {
  const [year, month, day] = date.split("-");
  const kMonth = `${parseInt(month)}월`;
  const kDay = `${parseInt(day)}일`;
  const dayIndex = new Date(`${year}-${month}-${day}`).getDay();
  const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
  const dayOfWeek = daysOfWeek[dayIndex];
  return `${kMonth} ${kDay} (${dayOfWeek})`;
};

export const getYMD = (date) => {
  let tmp = moment(date, "y-MM-DD HH:mm:ss");
  if (tmp.isValid()) return tmp.format("y-MM-DD");
  tmp = moment(date, "y-MM");
  if (tmp.isValid()) return tmp.format("y-MM");
  tmp = moment(date, "y");
  if (tmp.isValid()) return tmp.format("y");
};

export const getYMDHMS = (date) => {
  return moment(date, "y-MM-DD HH:mm:ss").format();
};

export const addDays = (date, addVal) => {
  return moment(date, "YYYY-MM-DD").add(addVal, "days").format("y-MM-DD");
};

export const addMonths = (date, addVal) => {
  return moment(date, "YYYY-MM-DD").add(addVal, "months").format("y-MM-DD");
};

export const getPeriod = (strMonth) => {
  const d = new Date();
  const paramDate = d.getFullYear() + "-" + lpad("00", strMonth) + "-01";
  const sd = moment(paramDate, "YYYY-MM-DD");
  const ed = moment(paramDate, "YYYY-MM-DD").add(1, "months").add(-1, "days");
  return [sd.format("y-MM-DD"), ed.format("y-MM-DD")];
};

export const getPeriodWithYear = (strYear, strMonth) => {
  const paramDate = strYear + "-" + lpad("00", strMonth) + "-01";
  const sd = moment(paramDate, "YYYY-MM-DD");
  const ed = moment(paramDate, "YYYY-MM-DD").add(1, "months").add(-1, "days");
  return [sd.format("y-MM-DD"), ed.format("y-MM-DD")];
};

export const makeMonth = (strYear, strMonth) => {
  const arr = [];
  const d = new Date();
  let yyyy = strYear || d.getFullYear();
  let mm = strMonth || d.getMonth() + 1;
  const firstDate = yyyy + "-" + lpad("00", mm) + "-01";
  const fd = moment(firstDate, "YYYY-MM-DD");
  const ed = moment(firstDate, "YYYY-MM-DD").endOf("month");
  const sdow = fd.toDate().getDay();
  // 이전달 표시.
  for (let idx = sdow; idx > 0; idx--) {
    const tmpDate = moment(firstDate, "YYYY-MM-DD").add(idx * -1, "days");
    arr.push({ ymd: tmpDate.format("y-MM-DD"), d: tmpDate.format("M/D") });
  }
  const max = ed.format("DD") * 1;
  for (let idx = 1; idx <= max; idx++) {
    const tmpDate = moment(firstDate, "YYYY-MM-DD").add(idx - 1, "days");
    arr.push({ ymd: tmpDate.format("y-MM-DD"), d: tmpDate.format("D") });
  }
  // 다음달 표시.
  const edow = ed.toDate().getDay();
  for (let idx = 1; idx < 7 - edow; idx++) {
    const tmpDate = moment(firstDate, "YYYY-MM-DD")
      .endOf("month")
      .add(idx, "days");
    arr.push({ ymd: tmpDate.format("y-MM-DD"), d: tmpDate.format("M/D") });
  }
  return arr;
};

export const dayofweek = () => {
  return new Date().getDay();
};

export const today = (days) => {
  let d = new Date();
  if (days) {
    d.setDate(d.getDate() + days);
  }
  let mm = d.getMonth() + 1;
  let dd = d.getDate();
  if (mm < 10) mm = "0" + mm;
  if (dd < 10) dd = "0" + dd;
  return d.getFullYear() + "-" + mm + "-" + dd;
};

export const todayK = () => {
  let d = new Date();
  let mm = d.getMonth() + 1;
  let dd = d.getDate();
  return d.getFullYear() + "년 " + mm + "월 " + dd + "일";
};

export const todate = (days) => {
  let d = moment();
  if (days) {
    d.add(days, "days");
  }
  return d;
};

/**
 * 주어진 월에 해당하는 첫날을 반환
 */
export const getFirstDayOfMonth = () => {
  const date = new Date();
  const d = new Date(date.getFullYear(), date.getMonth(), 1);
  let mm = d.getMonth() + 1;
  let dd = d.getDate();
  if (mm < 10) mm = "0" + mm;
  if (dd < 10) dd = "0" + dd;
  return d.getFullYear() + "-" + mm + "-" + dd;
};

/**
 * 주어진 월에 해당하는 마지막 날을 반환
 */
export const getLastDayOfMonth = () => {
  const date = new Date();
  const d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  let mm = d.getMonth() + 1;
  let dd = d.getDate();
  if (mm < 10) mm = "0" + mm;
  if (dd < 10) dd = "0" + dd;
  return d.getFullYear() + "-" + mm + "-" + dd;
};

/**
 *
 * @param {*} date YYYY-MM-DD 형태의 일자
 * @param {*} kind 뽑을 수치 구분(YYYY, MM, DD).
 */
export const detectOne = (date, kind) => {
  let d = new Date(date);
  let result = "";
  switch (kind) {
    case "MM":
      result = d.getMonth() + 1;
      break;
    case "DD":
      result = d.getDate();
      break;
    default:
      result = d.getFullYear();
  }
  return result;
};

/**
 *
 * @param {*} date YYYY-MM-DD~YYYY-MM-DD 형태의 문자열
 * @param {*} kind 뽑을 수치 구분(SYYYY, SMM, SDD, EYYYY, EMM, EDD).
 */
export const detectTwo = (date, kind) => {
  let result = "";
  if (date && date.length === 21) {
    let ds = date.split("~");
    if (kind.startsWith("S")) result = detectOne(ds[0], kind.substring(1));
    else result = detectOne(ds[1], kind.substring(1));
  }
  return result;
};

export const getThisMonth = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const thisMonth = year + "년 " + month + "월";
  return thisMonth;
};
export const getTodayWeek = () => {
  const today = new Date();
  const dayIndex = today.getDay();
  const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
  return daysOfWeek[dayIndex];
};
