export const userBgCode = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash;
  }
  const result = (Math.abs(hash) % 30) + 1;
  return result < 10 ? `user_bg0${result}` : `user_bg${result}`;
};
