import React, { useState } from "react";
// import { Link } from "react-router-dom";

import { HeaderArrow, ToTheTop } from "components";
import {
  LaborAttendanceCalendarWidget,
  LaborDailyAttendanceSummaryWidget,
  LaborAttendanceListComp,
} from "domain";

function LaborAttendanceHistory(props) {
  //===========================================================================
  const { $app, style, mstUid, mseUid, onChangeView, viewOptions, view } =
    props;
  const [toggleData, setToggleData] = useState({ ymd: "", open: false });
  //===========================================================================
  const handleDateClick = (ymd) => {
    if (toggleData.ymd === ymd) {
      setToggleData({ ymd, open: !toggleData.open });
    } else {
      setToggleData({ ymd, open: true });
    }
  };
  //===========================================================================
  return (
    <>
      <div className="comn_docker lb_color " style={style}>
        <header>
          <HeaderArrow
            $app={$app}
            onBack={() => {
              onChangeView({ view: viewOptions.MAIN });
            }}
            title="근태 내역"
          />
        </header>
        <div className="comn_layer nav-in_layer">
          <div className="ma_sub_main sub_main">
            <div className="cont_box boxShadow">
              <LaborAttendanceCalendarWidget
                $app={$app}
                mstUid={mstUid}
                mseUid={mseUid}
                onDateClick={handleDateClick}
              />
            </div>
            <div
              className={
                "cont_box wide_cont_box bg-wht" +
                (toggleData.open ? "" : " blind")
              }
            >
              <LaborDailyAttendanceSummaryWidget
                $app={$app}
                mstUid={mstUid}
                mseUid={mseUid}
                workDate={toggleData.ymd}
              />
            </div>
            <div className="cont_box wide_cont_box">
              <LaborAttendanceListComp
                $app={$app}
                view={view}
                mstUid={mstUid}
                mseUid={mseUid}
                onChangeView={onChangeView}
                viewOptions={viewOptions}
              />
            </div>
          </div>
        </div>
        <ToTheTop />
      </div>
    </>
  );
}

export default LaborAttendanceHistory;
