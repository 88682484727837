import React, { useState, useEffect } from "react";

import { addMonths, today } from "core";
import { StoreLaborEmployeeAttendancesHelper, DailyFilter } from "domain";
import { DailyAttendanceSum, DailyAttendanceList } from "../__private";

function LaborAttendanceListComp(props) {
  //===========================================================================
  const initFilters = () => {
    return {
      item: { id: "all", label: "전체" },
      startDate: addMonths(today(), -1),
      endDate: today(),
    };
  };

  //===========================================================================
  const { $app, mstUid, mseUid, onChangeView, viewOptions, view } = props;
  const [filters, setFilters] = useState(initFilters());
  const [data, setData] = useState([]);
  const [sumData, setSumData] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);
  //===========================================================================
  const handleFilters = (params) => {
    setFilters(params);
  };
  const makeLabelOptions = (resData) => {
    const newData = [...new Set(resData.map((item) => item.mseUid))].map(
      (mseUid) => resData.find((item) => mseUid === item.mseUid)
    );
    let tmplabelOptions = [{ id: "all", label: "전체", amountKind: 1000 }];
    newData.forEach((item, idx) => {
      tmplabelOptions.push({
        id: item.mseUid,
        label: item.employeeName,
        amountKind: idx,
      });
    });
    setLabelOptions(tmplabelOptions);
  };
  const calculatePayMins = (item) => {
    return (
      item.workNormal +
      item.workNormalOt * 1.5 +
      item.workNight * 1.5 +
      item.workNightOt * 2.0 +
      item.workHoliday * 1.5 +
      item.workHolidayOt * 2.0 +
      item.workHolidayNight * 2.0 +
      item.workHolidayNightOt * 2.5
    );
  };
  const isValidAttendance = (item) => {
    return item.start === 100 && item.end === 100;
  };
  const remakeData = (resData) => {
    const tmpData = [...resData, {}];
    let tmpArr = [];
    let tmpDate = resData[0]?.attendanceDate;
    let tmpGroup = [];
    let tmpMins = 0;
    let tmpPayMins = 0;
    let tmpWeek = resData[0]?.dayOfWeek;

    tmpData?.forEach((item) => {
      if (tmpDate !== item.attendanceDate) {
        tmpArr.push({
          date: tmpDate,
          week: tmpWeek,
          dailyMins: tmpMins,
          dailyPayMins: tmpPayMins,
          work: tmpGroup,
        });
        tmpDate = item.attendanceDate;
        tmpMins = 0;
        tmpPayMins = 0;
        tmpGroup = [];
        tmpWeek = item.dayOfWeek;
      }
      tmpMins = tmpMins + (isValidAttendance(item) ? item.workMinutes : 0);
      tmpPayMins =
        tmpPayMins + (isValidAttendance(item) ? calculatePayMins(item) : 0);
      tmpGroup.push(item);
    });
    setData(tmpArr);
  };

  const remakeSumData = (resData) => {
    const employeeDataMap = new Map();
    for (const item of resData) {
      const { mseUid, employeeName } = item;

      if (employeeDataMap.has(item.mseUid)) {
        const employeeData = employeeDataMap.get(item.mseUid);
        employeeDataMap.set(item.mseUid, {
          ...employeeData,
          totWorkMins:
            employeeData.totWorkMins +
            (isValidAttendance(item) ? item.workMinutes : 0),
          totPayMins:
            employeeData.totPayMins +
            (isValidAttendance(item) ? calculatePayMins(item) : 0),
        });
      } else {
        employeeDataMap.set(item.mseUid, {
          mseUid,
          employeeName,
          totWorkMins: isValidAttendance(item) ? item.workMinutes : 0,
          totPayMins: isValidAttendance(item) ? calculatePayMins(item) : 0,
        });
      }
    }
    setSumData([...employeeDataMap.values()]);
  };
  const loadData = () => {
    const params = Object.assign({}, filters, { mstUid: mstUid });
    StoreLaborEmployeeAttendancesHelper.history($app, params, (resData) => {
      const tmp = resData.filter((item) => item.mseUid === mseUid);
      // console.log("tmp ===>", tmp);
      if (tmp) {
        remakeData(tmp);
        remakeSumData(tmp);
        makeLabelOptions(tmp);
      }
    });
  };
  //===========================================================================
  useEffect(() => {
    if (view === viewOptions.ATTENDANCE) loadData();
  }, [filters, view]);
  //===========================================================================
  return (
    <>
      <div className="cont_title">근태 및 근로내역</div>
      <DailyFilter
        data={filters}
        labelOptions={labelOptions}
        onFilters={handleFilters}
        filterColor={"lb-filter-color"}
      />
      <DailyAttendanceSum data={sumData} />
      <DailyAttendanceList
        data={data}
        filters={filters.item}
        onChangeView={onChangeView}
        viewOptions={viewOptions}
      />
    </>
  );
}

export default LaborAttendanceListComp;
