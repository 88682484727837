import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";

import { clearAuth, HOSTNAME } from "core";
import { Modal } from "components";
import { AuthorityHelper } from "domain";

function HeaderRightProfile(props) {
  //===========================================================================
  const {} = props;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [logined, setLogined] = useRecoilState(AuthorityHelper.state);
  const [first, setFirst] = useState({});
  //===========================================================================
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const handleLogout = () => {
    clearAuth();
    setLogined(AuthorityHelper.initVal);
    window.location.href = "/";
  };
  //===========================================================================
  useEffect(() => {
    const arr = logined.stores.filter((store) => store.first);
    if (arr.length > 0) {
      setFirst(arr[0]);
    } else {
      if (logined.stores.length > 0) setFirst(logined.stores[0]);
    }
  }, [logined]);
  //===========================================================================
  const RightComp = useMemo(() => {
    let comp = (
      <></>
      // <Link to="/login" className="sm_btn">
      //   로그인
      // </Link>
    );
    if (logined?.rk) {
      comp = (
        <>
          <Link onClick={openModal} className="cr_user">
            <img
              src="https://modo-phinf.pstatic.net/20210428_251/1619620497378RyzHf_JPEG/mosaOn8uoT.jpeg?type=f320_320"
              alt=""
            />
          </Link>
        </>
      );
      if (logined.stores.length > 0) {
        comp = (
          <>
            <Link onClick={openModal} className="cr_user">
              <img
                src={HOSTNAME + "/api/v1/images/" + first.timUid + "/img.png"}
                alt=""
              />
            </Link>
          </>
        );
      }
    }
    return comp;
  }, [logined.rk, first]);
  //===========================================================================
  return (
    <>
      {RightComp}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        {/* <StorePopup
          first={first}
          onRequestClose={closeModal}
          onLogout={handleLogout}
        /> */}
      </Modal>
    </>
  );
}

export default HeaderRightProfile;
