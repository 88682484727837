import React, { useEffect, useState } from "react";

import {
  comma,
  today,
  minToHour,
  getLabelByCode,
  EMPLOY_TYPE_OPTIONS,
} from "core";

const TYPE_OPTIONS = [
  { label: "연장수당", value: 200 },
  { label: "야간수당", value: 300 },
  { label: "야간연장수당", value: 400 },
  { label: "휴일수당", value: 500 },
  { label: "휴일연장수당", value: 600 },
  { label: "휴일야간수당", value: 700 },
  { label: "휴일야간연장수당", value: 800 },
];

function LaborPayStubDetail(props) {
  //===========================================================================
  const { data, info, onRequestClose = () => {} } = props;
  const [newData, setNewData] = useState({});
  const [hourData, setHourData] = useState({});
  //===========================================================================
  const sortByAmount = (arr) => {
    arr.sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount));
    return arr;
  };
  //===========================================================================
  useEffect(() => {
    let payroll = []; // 지급액
    let payrollTot = 0;
    let deductions = []; // 공제액
    let deductionsTot = 0;
    let contribution = []; // 사업자부담금
    let contributionTot = 0;
    data.detailsJson?.forEach((item) => {
      if (item.type < 5000) {
        payroll.push(item);
        payrollTot += parseFloat(item.amount);
      } else if (5000 <= item.type && item.type < 9000) {
        deductions.push(item);
        deductionsTot += parseFloat(item.amount);
      } else if (item.type >= 9000) {
        contribution.push(item);
        contributionTot += parseFloat(item.amount);
      }
    });
    setNewData({
      payroll: sortByAmount(payroll),
      payrollTot,
      deductions: sortByAmount(deductions),
      deductionsTot,
      contribution: sortByAmount(contribution),
      contributionTot,
    });
  }, [data]);

  useEffect(() => {
    let result = {};
    TYPE_OPTIONS.forEach((item) => {
      data.detailsJson?.forEach((data) => {
        if (item.value == data.type * 1)
          result = { ...result, ...{ ["h" + item.value]: data.minutes } };
      });
    });
    setHourData(result);
  }, [data]);

  //===========================================================================
  return (
    <>
      <div className="pop_layer pop_bt_layer">
        <div className="pop_bt_cont bg-wht">
          <div className="popup_header">
            <span className="title">
              명세서 상세 : {info.storeName} {info.storeChainName}{" "}
            </span>
            <span className="closed_btn" onClick={onRequestClose}></span>
          </div>
          <div className="popup_mid paystub_mid">
            <div className="paystub_box">
              {/* <!--근로자일 경우, ctr_hd 클래스에 worker 클래스 추가-->
                    <!--퇴사자일 경우, ctr_hd 클래스에 leave 클래스 추가--> */}

              <div
                className={
                  "ctr_hd" +
                  (info.workEndDate && info.workEndDate < today()
                    ? " leave"
                    : " worker")
                }
              >
                <div className="info-box">
                  <div className="ct_worker_info">
                    <i className="thumb df_user_icon"></i>
                    <ul className="info">
                      <li className="name">{info.employeeName}</li>
                      <li className="place sub_info">
                        {getLabelByCode(
                          EMPLOY_TYPE_OPTIONS,
                          info?.employmentType
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sum_salary">
                  {comma(Math.floor(data.amount))}
                </div>
              </div>

              <ul className="paystub_vlaue_lt">
                <li className="item payment_sum sum">
                  <p className="title">지급액 계</p>
                  <span className="value">
                    {comma(Math.floor(newData.payrollTot))}
                  </span>
                </li>
                {newData.payroll?.map((item, idx) => (
                  <li className="item" key={idx}>
                    <p className="title">{item.name}</p>
                    <span className="value">
                      {comma(Math.floor(item.amount))}
                    </span>
                  </li>
                ))}
              </ul>
              <ul
                className={
                  "paystub_vlaue_lt" +
                  (newData.deductionsTot < 1 ? " blind" : "")
                }
              >
                <li className="item deduction_sum sum">
                  <p className="title">공제내역 계</p>
                  <span className="value">
                    {comma(Math.floor(newData.deductionsTot))}
                  </span>
                </li>
                {newData.deductions?.map((item, idx) => (
                  <li className="item" key={idx}>
                    <p className="title">{item.name}</p>
                    <span className="value">
                      {comma(Math.floor(item.amount))}
                    </span>
                  </li>
                ))}
              </ul>

              {/* <ul
                className={
                  "paystub_vlaue_lt" +
                  (newData.contributionTot < 1 ? " blind" : "")
                }
              >
                <li className="item contribution_sum sum">
                  <p className="title">사업자 부담금 계</p>
                  <span className="value">
                    {comma(Math.floor(newData.contributionTot))}
                  </span>
                </li>
                {newData.contribution?.map((item, idx) => (
                  <li className="item" key={idx}>
                    <p className="title">{item.name}</p>
                    <span className="value">
                      {comma(Math.floor(item.amount))}
                    </span>
                  </li>
                ))}
              </ul> */}
            </div>
            <div className="work_timetable_box mt-20px">
              <div className="title_box">
                <p className="title">근무내역</p>
                <span className="sub_title">단위 : 시간</span>
              </div>
              <ul className="work_timetable_lt">
                <li>
                  <p className="time">
                    {Math.ceil(minToHour(data.workMinutes || 0))}
                  </p>
                  <span className="title">총 근로</span>
                </li>
                <li>
                  <p className="time">
                    {Math.ceil(minToHour(hourData?.h200 || 0))}
                  </p>
                  <span className="title">연장</span>
                </li>
                <li>
                  <p className="time">
                    {Math.ceil(minToHour(hourData?.h300 || 0))}
                  </p>
                  <span className="title">야간</span>
                </li>
                <li>
                  <p className="time">
                    {Math.ceil(minToHour(hourData?.h500 || 0))}
                  </p>
                  <span className="title">휴일</span>
                </li>
                <li>
                  <p className="time">{Math.ceil(minToHour(0))}</p>
                  <span className="title">연차</span>
                </li>
                {hourData?.h400 > 0 ? (
                  <li>
                    <p className="time">
                      {Math.ceil(minToHour(hourData?.h400 || 0))}
                    </p>
                    <span className="title">야간+연장</span>
                  </li>
                ) : (
                  <></>
                )}
                {hourData?.h600 > 0 ? (
                  <li>
                    <p className="time">
                      {Math.ceil(minToHour(hourData?.h600 || 0))}
                    </p>
                    <span className="title">휴일+연장</span>
                  </li>
                ) : (
                  <></>
                )}
                {hourData?.h700 > 0 ? (
                  <li>
                    <p className="time">
                      {Math.ceil(minToHour(hourData.h700 || 0))}
                    </p>
                    <span className="title">휴일+야간</span>
                  </li>
                ) : (
                  <></>
                )}
                {hourData?.h800 > 0 ? (
                  <li className="max">
                    <p className="time">
                      {Math.ceil(minToHour(hourData.h800 || 0))}
                    </p>
                    <span className="title">휴일+연장+야간</span>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LaborPayStubDetail;
