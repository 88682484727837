import React from "react";
import Select from "react-select";

function SelectW({
  onChange = () => {},
  defaultValue = "",
  value = "",
  options = [],
  className = "",
  isSearchable = false,
  placeholder = "",
}) {
  //===========================================================================
  return (
    <Select
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      options={options}
      placeholder={placeholder}
      className={className}
      isSearchable={isSearchable || false}
    ></Select>
  );
}

export default SelectW;
