import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function HomePage() {
  //===========================================================================
  const navigate = useNavigate();
  useEffect(() => {
    window.location.href = "/info";
  }, []);
  //===========================================================================
  return <></>;
}

export default HomePage;
