import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";

import { CertificationNumberHelper } from "domain";

const initData = {
  certNum: "",
};
const initMsgs = [];

function CertificationForm(props) {
  //===========================================================================
  const { $app, accessInfo } = props;
  const [formData, setFormData] = useState(initData);
  const [msgs, setMsgs] = useState(initMsgs);
  const [btnOn, setBtnOn] = useState({ send: true, cert: false });
  const [countdown, setCountdown] = useState(Date.now());
  //   const setLogined = useSetRecoilState(AuthorityHelper.state);
  const navigate = useNavigate();
  //===========================================================================
  const handleInputChange = (e) => {
    setFormData(
      Object.assign({}, formData, { [e.target.name]: e.target.value })
    );
  };
  const handleRequestSendNum = () => {
    if (btnOn.send) {
      const { targetName, targetPhone } = accessInfo;
      const params = { targetName, targetPhone };
      CertificationNumberHelper.send($app, params);
      setBtnOn({ send: false, cert: true });
      setCountdown(Date.now() + 300000);
    }
  };
  const handleCertSubmit = () => {
    if (btnOn.cert && isValidData()) {
      const { targetName, targetPhone } = accessInfo;
      const { certNum } = formData;
      const params = { targetName, targetPhone, certNum };
      CertificationNumberHelper.check(
        $app,
        params,
        () => {
          navigate("/contract");
        },
        ({ msg }) => {
          alert("fail:" + msg);
          navigate("/contract"); // 템플릿 승인전까지만 테스트용으로 사용.
        }
      );
    }
  };
  const handleCountdownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      if (btnOn.cert) setBtnOn({ send: true, cert: false });
      return <></>;
    } else {
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };
  //===========================================================================
  const isValidData = () => {
    let isValid = true;
    const { certNum } = formData;
    let tmpMsgs = [];
    if (certNum.length === 0) tmpMsgs.push("인증번호를 입력해주세요");
    if (certNum.length !== 6) tmpMsgs.push("인증번호 6자를 입력해주세요");
    if (tmpMsgs.length > 0) isValid = false;
    setMsgs(tmpMsgs);
    return isValid;
  };
  //===========================================================================
  const MsgsComp = useMemo(() => {
    return msgs.map((msg, idx) => (
      <span key={idx} className="noti_txt">
        {msg}
      </span>
    ));
  }, [msgs]);
  //===========================================================================
  return (
    <>
      <div className="certification-cont">
        <div className="cert-btn-flex mb16px">
          <span className="item">
            <input
              name="targetPhone"
              className="comn_ip wide login_ip cert-form"
              type="text"
              readOnly
              value={accessInfo.targetPhone}
            />
          </span>
          <span className="item btn">
            <span
              className={"cert-btn b-shadow" + (btnOn.send ? " on" : "")}
              onClick={handleRequestSendNum}
            >
              전송
            </span>
          </span>
        </div>
        <Countdown
          key={countdown}
          date={countdown}
          renderer={handleCountdownRenderer}
        />
        <div className="cert-btn-flex">
          <span className="item">
            <input
              name="certNum"
              onChange={handleInputChange}
              className="comn_ip wide login_ip cert-form"
              type="number"
              placeholder="인증번호 입력"
              maxLength={6}
            />
          </span>
          <span className="item btn">
            <span
              className={"cert-btn b-shadow" + (btnOn.cert ? " on" : "")}
              onClick={handleCertSubmit}
            >
              인증
            </span>
          </span>
        </div>
      </div>
      <div className="error">{MsgsComp}</div>
    </>
  );
}

export default CertificationForm;
