import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";

import { getOnChangeView, setOnChangeView } from "core";
import { ContractForm, ContractDetail } from "./__view";
// import { ContractAddDocComp } from "./comp";
import { AuthorityHelper, StoreLaborContractHelper } from "domain";

const VIEW = {
  MAIN: 100,
  FORM: 200,
  DETAIL: 300,
  CAMERA: 400,
};

function ContractHomePage(props) {
  //===========================================================================
  const { $app } = props;
  const [view, setView] = useState(VIEW.FORM);
  const [contractData, setContractData] = useState({});
  const accessInfo = useRecoilValue(AuthorityHelper.tokenState);
  // const [docList, setDocList] = useState({});
  const linkToChangeView = getOnChangeView();
  console.log("accessInfo ===>", accessInfo);
  const navigate = useNavigate();
  //===========================================================================
  const handleChangeView = ({ view, contractData }) => {
    setView(view);
    setContractData(contractData);
    // setDocList(docList);
  };
  useEffect(() => {
    if (linkToChangeView) {
      handleChangeView({ view: VIEW[linkToChangeView] });
      setOnChangeView("");
    }
  }, [linkToChangeView]);

  //인증후 확인용: 로그인과 회원가입 만들기 전까지만 사용
  useEffect(() => {
    if (accessInfo?.tecUid) {
      StoreLaborContractHelper.one(
        $app,
        { tecUid: accessInfo.tecUid },
        (resData) => {
          console.log("tecUid resData===>", resData);
          if (resData?.state === 300) navigate("/labor");
        }
      );
    }
  }, [accessInfo?.tecUid]);
  //===========================================================================
  return (
    <>
      <ContractForm
        $app={$app}
        onChangeView={handleChangeView}
        viewOptions={VIEW}
        style={{ display: view === VIEW.FORM ? "" : "none" }}
      />
      <ContractDetail
        $app={$app}
        tecUid={accessInfo.tecUid}
        contractData={contractData}
        onChangeView={handleChangeView}
        viewOptions={VIEW}
        style={{ display: view === VIEW.DETAIL ? "" : "none" }}
      />
      {/* <ContractAddDocComp
        $app={$app}
        tecUid={tecUid}
        docList={docList}
        onChangeView={handleChangeView}
        viewOptions={VIEW}
        style={{ display: view === VIEW.ADD_DOC ? "" : "none" }}
      /> */}
    </>
  );
}

export default ContractHomePage;
