import { RadioContext } from "./RadioContext";

function RadioGroup({ label, className, children, ...rest }) {
  return (
    <fieldset className={className}>
      {/* <legend>{label}</legend> */}
      <RadioContext.Provider value={rest}>{children}</RadioContext.Provider>
    </fieldset>
  );
}

export default RadioGroup;
