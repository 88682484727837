import { BaseHelper } from "domain/common";
import { Caller } from "./__private";

const { nfn, wc, ex } = BaseHelper;
const { dftVal, state, daily, start, end, edit, history } = Caller;

export const StoreLaborEmployeeAttendancesHelper = {
  dftVal,
  state,
  ...ex(nfn, nfn, nfn, nfn),
  daily: wc(daily),
  start: wc(start),
  end: wc(end),
  edit: wc(edit),
  history: wc(history),
};
