import React, { useEffect, useState } from "react";

import { HOSTNAME } from "core";

function ImageGallery(props) {
  //===========================================================================
  const { docImages, onRequestClose = () => {} } = props;
  const [selectedImage, setSelectedImage] = useState({});
  const [selectedNum, setSelectedNum] = useState(1);
  //===========================================================================
  useEffect(() => {
    if (docImages?.files?.length > 0) setSelectedImage(docImages?.files[0]);
    else setSelectedImage({});
  }, [docImages]);
  //===========================================================================
  const handleImageClick = (image, idx) => {
    setSelectedImage(image);
    setSelectedNum(idx + 1);
  };
  //===========================================================================
  return (
    <>
      <div className="pop_layer pop_bt_layer gallery_pop full-popup ">
        <div className="popup_header">
          <span className="closed_btn" onClick={onRequestClose}></span>
          <div className="title_box">
            <p className="title">{docImages?.name}</p>
            <span className="date">
              {docImages?.storeName} {docImages?.storeChainName}
            </span>
          </div>
        </div>
        <div className="popup_cont">
          <div className="img_slider">
            {selectedImage ? (
              <img
                src={`${HOSTNAME}/api/v1/images/${selectedImage}/img.png`}
                alt=""
              />
            ) : (
              <img src={selectedImage.fileEntryURL} alt="" />
            )}
          </div>
        </div>
        <div className="btt">
          <div className="gallery_thumb_packing">
            <div className="gallery_thumb">
              <ul className="thumb_lt">
                {docImages?.files?.map((image, idx) => (
                  <li key={idx} onClick={() => handleImageClick(image, idx)}>
                    {image ? (
                      <img
                        src={`${HOSTNAME}/api/v1/images/${image}/img.png`}
                        alt=""
                      />
                    ) : (
                      <img src={image.fileEntryURL} alt="" />
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="thumb_count">
              <span className="numb">{selectedNum}</span>
              <span>{docImages?.files?.length}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageGallery;
