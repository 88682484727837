import { atom } from "recoil";
import { post, get } from "core";

const defaultValue = [];

export const Caller = {
  //===========================================================================
  dftVal: defaultValue,
  state: atom({
    key: "storeLaborEmployeeAttendanceState",
    default: defaultValue,
  }),
  //===========================================================================
  // 오늘 근무자 : mstUid, startDate, endDate
  daily: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, { qs: "", page: 0, size: 1000 }, params);
    $app.req({
      fn: async () => {
        return await get("/api/v1/store/employee/attendances/daily", tmp);
      },
      sfn: ({ data }) => {
        sfn(data.storeEmployeeAttendances, data);
      },
      ffn: ({ status }) => {
        ffn(status);
      },
    });
  },

  // 출근 체크 : mseUid, tecUid, startTime, start, attendanceDate
  start: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/store/employee/attendances/goto/work", tmp);
      },
      sfn: ({ data }) => {
        sfn(data.storeEmployeeAttendance, data);
      },
      ffn: ({ status }) => {
        ffn(status);
      },
    });
  },

  // 퇴근 체크 : mseUid, tecUid, endTime, end, attendanceDate
  end: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/store/employee/attendances/leave/work", tmp);
      },
      sfn: ({ data }) => {
        sfn(data.storeEmployeeAttendance, data);
      },
      ffn: ({ status }) => {
        ffn(status);
      },
    });
  },

  // 출퇴근 수정: mseUid, tecUid, startTime, endTime, attendanceDate
  edit: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, {}, params);
    $app.req({
      fn: async () => {
        return await post("/api/v1/store/employee/attendances/edit", tmp);
      },
      sfn: ({ data }) => {
        sfn(data.storeEmployeeAttendance, data);
      },
      ffn: ({ status }) => {
        ffn(status);
      },
    });
  },

  // 출퇴근 내역 : mstUid, startDate, endDate, 선택: mseUid
  history: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign({}, { qs: "", page: 0, size: 1000 }, params);
    $app.req({
      fn: async () => {
        return await get("/api/v1/store/employee/attendances/history", tmp);
      },
      sfn: ({ data }) => {
        sfn(data.storeEmployeeAttendances, data);
      },
      ffn: ({ status }) => {
        ffn(status);
      },
    });
  },
};
