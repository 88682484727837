import { useContext } from "react";
import { RadioContext } from "./RadioContext";

function Radio({ children, value, defaultChecked, disabled, className }) {
  const group = useContext(RadioContext);
  const checked = group.value !== undefined ? value === group.value : undefined;
  return (
    <div>
      <label className={className + (checked ? " checked" : "")}>
        <input
          type="radio"
          value={value}
          name={group.key + "_" + group.name}
          defaultChecked={defaultChecked}
          disabled={disabled || group.disabled}
          checked={checked}
          onChange={(e) => group.onChange && group.onChange(e.target.value)}
        />
        {children}
      </label>
    </div>
  );
}

export default Radio;
