import { atom } from "recoil";
import { get } from "core";

const defaultValue = [];

export const Caller = {
  //===========================================================================
  dftVal: defaultValue,
  state: atom({
    key: "commonAddressState",
    default: defaultValue,
  }),
  //===========================================================================
  search: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign(
      {},
      { qs: "", currentPage: 1, countPerPage: 100 },
      params
    );
    $app.req({
      fn: async () => {
        return await get("/api/v1/address", tmp);
      },
      sfn: ({ data }) => sfn(data.addresses, data),
      ffn: ({ status }) => ffn(status),
    });
  },
  coordinate: ({ $app, params, sfn = () => {}, ffn = () => {} }) => {
    let tmp = Object.assign(
      {},
      { qs: "", currentPage: 1, countPerPage: 100 },
      params
    );
    $app.req({
      fn: async () => {
        return await get("/api/v1/coordinates", tmp);
      },
      sfn: ({ data }) => sfn(data.coordinate, data),
      ffn: ({ status }) => ffn(status),
    });
  },
};
