import React, { useState, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";

import {
  makeMonth,
  getPeriodWithYear,
  TOTAL_VIEW,
  getMstUidsParam,
  today,
} from "core";
import { AuthorityHelper, StoreLaborEmployeeOvertimeHelper } from "domain";

const defaultYear = new Date().getFullYear();
const defaultMonth = new Date().getMonth() + 1;

function LaborOvertimeCalendarWidget(props) {
  //===========================================================================
  const initMonth = () => {
    return makeMonth(defaultYear, defaultMonth);
  };
  const getMstUids = () => {
    return TOTAL_VIEW.ALL === viewType
      ? logined.stores.map((store) => store.uid)
      : getMstUidsParam(logined);
  };
  const initFilters = () => {
    const [startDate, endDate] = getPeriodWithYear(defaultYear, defaultMonth);
    const mstUids = getMstUids();
    const orderFlag = "";
    const filters = {
      year: defaultYear % 2000, // 화면용
      month: defaultMonth, // 화면용
      mstUids,
      startDate,
      endDate,
      orderFlag,
    };
    return filters;
  };
  //===========================================================================
  const { $app, viewType, overtimeUuid, onDateClick = () => {} } = props;
  const logined = useRecoilValue(AuthorityHelper.state);
  const [month, setMonth] = useState(initMonth());
  const [filters, setFilters] = useState(initFilters());
  const [monthData, setMonthData] = useState([]);
  const [isSelect, setIsSelect] = useState(null);
  //===========================================================================
  useEffect(() => {
    setFilters({ ...filters, mstUids: getMstUids() });
  }, [viewType, logined]);

  // useEffect(() => {
  //   StoreLaborEmployeeOvertimeHelper.daily($app, filters, setMonthData);
  // }, [filters, overtimeUuid]);
  //===========================================================================
  const handleClickPrev = () => {
    let tmpY = filters.year * 1;
    const tmpM = filters.month * 1;
    let prevNum = tmpM - 1;
    if (prevNum <= 0) {
      tmpY = tmpY - 1;
      prevNum = 12;
    }
    const [startDate, endDate] = getPeriodWithYear(tmpY + 2000, prevNum);
    setMonth(makeMonth(tmpY + 2000, prevNum));
    setFilters((prevState) => ({
      ...prevState,
      year: startDate.substring(2, 4),
      month: prevNum,
      startDate,
      endDate,
    }));
    setIsSelect(null);
  };
  const handleClickNext = () => {
    let tmpY = filters.year * 1;
    const tmpNum = filters.month * 1;
    let nextNum = tmpNum + 1;
    if (nextNum > 12) {
      tmpY = tmpY + 1;
      nextNum = 1;
    }
    const [startDate, endDate] = getPeriodWithYear(tmpY + 2000, nextNum);
    setMonth(makeMonth(tmpY, nextNum));
    setFilters((prevState) => ({
      ...prevState,
      year: startDate.substring(2, 4),
      month: nextNum,
      startDate,
      endDate,
    }));
    setIsSelect(null);
  };

  //===========================================================================
  const MonthComp = useMemo(() => {
    const handleDateClick = (ymd, idx) => {
      setIsSelect(isSelect === idx ? null : idx);
      onDateClick(ymd);
    };
    return (
      <ul className="content_date">
        {month?.map((day, idx) => {
          let tmp = monthData?.filter((item) => item.workDate === day.ymd)[0];
          return (
            <li
              key={idx}
              className={
                "item to day" + (isSelect === idx ? " selected-on" : "")
              }
            >
              <Link
                onClick={() => handleDateClick(day.ymd, idx)}
                className="m-v_btn"
              ></Link>
              <p className="date">
                <span
                  className={day.ymd === today() ? " today-on labor-bg" : ""}
                >
                  {day.d}
                </span>
              </p>
              {tmp?.payTot ? (
                <span className="overtime-pay">
                  {Math.floor((tmp?.payTot / 10000) * 10) / 10 || ""}
                </span>
              ) : (
                <></>
              )}
            </li>
          );
        })}
      </ul>
    );
  }, [monthData, isSelect, month, onDateClick]);
  //===========================================================================
  return (
    <div className="callendar_packing">
      <div className="header labor-bg">
        <div className="month">
          <button className="left month_btn" onClick={handleClickPrev}>
            {filters.month === 1
              ? filters.year - 1 + "년" + 12
              : filters.month - 1}
            월
          </button>
          <span className="month_txt">
            {filters.year}년 {filters.month}월
          </span>
          <button className="right month_btn" onClick={handleClickNext}>
            {filters.month === 12
              ? filters.year * 1 + 1 + "년" + 1
              : filters.month + 1}
            월
          </button>
        </div>
        <div className="date">
          <p className="item">일</p>
          <p className="item">월</p>
          <p className="item">화</p>
          <p className="item">수</p>
          <p className="item">목</p>
          <p className="item">금</p>
          <p className="item">토</p>
        </div>
      </div>
      {MonthComp}
      <div className="callendar-price-unit labor-bg">
        <span>단위: 만원&nbsp;</span>
      </div>
    </div>
  );
}

export default LaborOvertimeCalendarWidget;
